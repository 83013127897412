import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox={'0 0 20 20'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeWidth={2}
      d="M19 8.414V19H1V1h10.586L19 8.414Z"
    />
    <path
      stroke={props.fill || '#fff'}
      strokeWidth={2}
      d="M13 7V2.414L17.586 7H13Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M3 5h6v1H3zM3 11h14v1H3zM3 14h14v1H3z"
    />
  </svg>
);
export default SvgComponent;
