import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {FontItem} from '../../../interfaces/screens/fontChanger';
import {updateAppFont} from './actions';
import {store} from '../../../redux/store';
import {AuthState} from '../../../interfaces/reducers/auth';
import Switch from 'react-switch';
import {tr} from '../../../translation';
import RangeSlider from '../../../components/rangeSlider';
import {fontSizeOptions} from '../../../assets/static/fontChanger';
import {Theme} from '../../../theme/lightTheme';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4%;
  margin-bottom: 5%;
  margin-top: 5%;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  width: ${({theme}) => (theme?.isMobile ? '90%' : '55%')};
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  border-radius: 26px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 95%;
  align-self: center;
  @media (min-width: 768px) {
    width: 75%;
  }
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s6};
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 3%;
`;
const Subtitle = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  text-align: center;
  letter-spacing: 0.5px;
  padding-bottom: 7%;
`;
const RandomText = styled.p`
  color: ${({theme}) => theme.text.dark};
  text-align: center;
`;
const NeumorphicContainer = styled.div`
  width: 80%;
  min-height: 15vh;
  border-radius: 20px;
  background: ${({theme}) => theme.text.light + 'CC'};
  box-shadow:
    4px 4px 8px rgba(190, 190, 190, 0.3),
    -4px -4px 8px rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4% 5%;
  margin-bottom: 10%;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  background: rgba(248, 248, 248, 0.07);
  box-shadow:
    8px 8px 16px rgba(190, 190, 190, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.2);
  padding: 2% 3%;
  border-radius: 8px;
`;
const Label = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  padding: 2%;
`;

const FontChanger = () => {
  const theme = useTheme() as Theme;
  const {config} = store.getState().auth as AuthState;
  const {fontWeight, item} = config?.font || {};
  const [isEnabled, setFontWeightEnabled] = useState(fontWeight === 'bold');
  const [selectedItem, setSelectedItem] = useState<FontItem>(item);

  useEffect(() => {
    const temp = {
      id: selectedItem?.label,
      item: selectedItem,
      fontSize: selectedItem?.textSize,
      fontWeight: isEnabled ? 'bold' : 'normal',
    };
    updateAppFont(temp);
  }, [selectedItem, isEnabled]);

  return (
    <Container>
      <FormContainer>
        <Title>{tr('fontChanger.headerTitle')}</Title>
        <Subtitle>{tr('fontChanger.title')}</Subtitle>
        <NeumorphicContainer>
          <RandomText
            style={{
              fontSize: selectedItem?.textSize || 17,
              fontWeight: isEnabled ? 'bold' : 'normal',
            }}>
            {tr('fontChanger.randomText')}
          </RandomText>
        </NeumorphicContainer>
        <InputContainer>
          <RangeSlider
            min={1}
            max={fontSizeOptions.length}
            step={1}
            value={selectedItem?.value || 2}
            options={fontSizeOptions}
            onChange={item => setSelectedItem(item)}
          />
        </InputContainer>
        <Row>
          <Label>{tr('fontChanger.boldFont')}</Label>
          <Switch
            onChange={val => setFontWeightEnabled(val)}
            checked={isEnabled}
            offColor={theme.text.border}
            onColor={'#00adff'}
          />
        </Row>
      </FormContainer>
    </Container>
  );
};

export default FontChanger;
