import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    viewBox={'0 0 18 18'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#ffffff'}
      d="M0 0v17.841h17.992C8.054 17.837 0 9.851 0 0Z"
    />
  </svg>
);
export default SvgComponent;
