import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={16}
    viewBox={'0 0 10 16'}
    {...props}
    fill="none"
    stroke="none">
    <path
      fill={props.fill || '#000'}
      strokeWidth={1}
      stroke={props.fill || '#000'}
      fillRule="evenodd"
      d="M1.5.125A.875.875 0 0 1 2.375 1v14a.875.875 0 1 1-1.75 0V1A.875.875 0 0 1 1.5.125Zm7 0A.875.875 0 0 1 9.375 1v14a.875.875 0 1 1-1.75 0V1A.875.875 0 0 1 8.5.125Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
