import React, {createContext, useContext, useMemo, useState} from 'react';
import {MainLayoutProps} from '../../interfaces/screens/mainLayout';
import {useDeepCompareEffect} from '../../utils/hooks/useDeepCompareEffect';

export interface MainLayoutState {
  props: MainLayoutProps;
  setProps: React.Dispatch<React.SetStateAction<MainLayoutProps>>;
}

const MainLayoutContext = createContext<MainLayoutState>(undefined);

export const useMainLayout = () => {
  const context = useContext(MainLayoutContext);
  if (!context) {
    throw new Error('useMainLayout must be used within a HeaderProvider');
  }
  return context;
};

export const MainLayoutProvider: React.FC<any> = ({children}) => {
  const [props, setProps] = useState<MainLayoutProps>({});

  return (
    <MainLayoutContext.Provider value={{props, setProps}}>
      {children}
    </MainLayoutContext.Provider>
  );
};

// Custom hook to set the Main Layout Props
export const useSetMainLayoutProps = (screenProps?: MainLayoutProps) => {
  const {setProps} = useMainLayout();
  const memoizedScreenProps = useMemo(() => screenProps, [screenProps]);

  // Use the custom deep comparison hook
  useDeepCompareEffect(() => {
    if (memoizedScreenProps !== null) {
      setProps(memoizedScreenProps || {}); // Set new screenProps only if changed
    }
  }, [memoizedScreenProps]);
};
