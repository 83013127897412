import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={102}
    height={136}
    viewBox={'0 0 102 136'}
    {...props}
    fill="none"
    stroke="none">
    <path
      fill={'#009F9C' || props.fill}
      d="M48.057 2.059 2.604 47.513a4.545 4.545 0 1 0 6.428 6.428l42.24-42.24 42.241 42.24a4.546 4.546 0 0 0 6.428-6.428L54.485 2.06a4.544 4.544 0 0 0-6.428 0Z"
    />
    <path
      fill={'#009F9C' || props.fill}
      d="M47.785 42.331 2.33 87.786a4.545 4.545 0 1 0 6.429 6.428L51 51.974l42.24 42.24a4.546 4.546 0 0 0 6.429-6.428L54.213 42.33a4.544 4.544 0 0 0-6.428 0Z"
    />
    <path
      fill={'#009F9C' || props.fill}
      d="M47.512 82.604 2.06 128.06a4.545 4.545 0 1 0 6.428 6.428l42.24-42.24 42.241 42.24a4.546 4.546 0 1 0 6.428-6.429L53.94 82.604a4.544 4.544 0 0 0-6.428 0Z"
    />
  </svg>
);
export default SvgComponent;
