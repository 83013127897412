import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
/**
 * Encrypts data using AES encryption.
 * @param {any} data - The data to be encrypted.
 * @returns {string} - The encrypted data as a string.
 */
const encryptData = (data: any): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

/**
 * Decrypts data using AES decryption.
 * @param {string} encryptedData - The data to be decrypted.
 * @returns {any} - The decrypted data.
 */
const decryptData = (encryptedData: string): any => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

/**
 * Retrieves the local data stored with the specified key.
 * @param {string} key - The key used to store the data.
 * @returns {Promise<any | null>} - A promise that resolves to the retrieved data or null if it doesn't exist.
 */
export const getLocalData = async (key: string): Promise<any | null> => {
  try {
    const jsonValue = localStorage.getItem(key);
    if (jsonValue) {
      try {
        return decryptData(jsonValue);
      } catch (err) {
        return null;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Stores the specified value in the local storage with the given key.
 * @param {string} key - The key used to store the data.
 * @param {any} value - The value to be stored.
 * @returns {Promise<boolean>} - A promise that resolves to true if the value is successfully stored, or rejects with an error if an error occurs.
 */
export const setLocalData = async (
  key: string,
  value: any,
): Promise<boolean> => {
  try {
    const encryptedValue = encryptData(value);
    localStorage.setItem(key, encryptedValue);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Clears all the data stored in the local storage.
 * @returns {Promise<boolean>} - A promise that resolves to true if the data is successfully cleared, or false if an error occurs.
 */
export const flushLocalData = async (): Promise<boolean> => {
  try {
    localStorage.clear();
    return true;
  } catch (err) {
    return false;
  }
};
