import {colors} from './colors';
import useWindowDimensions from '../utils/hooks/useWindowDimensions';
import {getByScreenSize, hdp, wdp} from '../utils/responsive';

/**
 * Defines the light theme for the application.
 *
 * This function returns a comprehensive object containing styles and colors for the light theme.
 *
 * @returns - An object representing the light theme, including color palettes, fonts, text styles, and component-specific styles.
 */
const height = window.innerHeight;
const lightTheme = () => {
  const {isMobile} = useWindowDimensions();
  return {
    primary: colors.primary,
    secondary: colors.secondary,
    homeBackground: '#FFFFFF',
    border: '#D9D9D9',
    shadow: 'rgba(0,0,0,0.4)',
    ripple: colors.ripple,
    skeletonBackground: '#aaaaaa',
    overlay: 'rgba(0,0,0,0.2)',
    overlayScreen: 'rgba(0,0,0,0.5)',
    defaultOverlayScreen: 'rgba(0,0,0,0.1)',
    dimensions: {
      hdp: (percent: number) => hdp(percent),
      wdp: (percent: number) => wdp(percent),
      getByScreenSize: (smValue: any, lgValue: any, xsmValue: any = smValue) =>
        getByScreenSize(smValue, lgValue, xsmValue),
    },
    isMobile,
    alert: {
      backgroundPrimary: colors.primary,
      textPrimary: '#FFFFFF',
    },
    fonts: {
      ar: '"Ubuntu", Arial, sans-serif',
      en: '"Helvetica", Arial, sans-serif',
    },
    text: {
      dark: colors.title,
      black: '#181818',
      border: '#cccccc',
      icon: colors.icon,
      error: colors.error,
      grey: colors.description,
      light: '#ffffff',
      title: colors.title,
      description: colors.description,
      textPrimary: '#242424',
      placeholder: '#FFFFFF',
      borderLight: '#FFFFFF',
      borderFocus: '#80CBC4',
      s1: height >= 900 ? '4.5rem' : '4rem',
      s2: height >= 900 ? '4rem' : '3.25rem',
      s3: height >= 900 ? '2.75rem' : '2rem',
      s35: height >= 900 ? '1.8rem' : '1.4rem',
      s4: height >= 900 ? '1.5rem' : '1.2rem',
      s5: height >= 900 ? '1.4rem' : '1rem',
      s55: height >= 900 ? '1.2rem' : '0.8rem',
      s6: height >= 900 ? '1.2rem' : '0.8rem',
      s7: height >= 900 ? '1rem' : '0.75rem',
      s8: height >= 900 ? '0.875rem' : '0.7rem',
      s85: height >= 900 ? '0.875rem' : '0.7rem',
      s89: height >= 900 ? '0.825rem' : '0.675rem',
      s9: height >= 900 ? '0.8rem' : '0.65rem',
      s10: height >= 900 ? '0.7rem' : '0.6rem',
      s11: height >= 900 ? '0.65rem' : '0.55rem',
      s12: height >= 900 ? '0.5rem' : '0.4rem',
    },
    button: {
      text: '#FFFFFF',
      lightColor: '#FFFFFF',
      outline: colors.primary,
    },
    textInput: {
      cursorColor: colors.primary,
      placeholder: '#DDDDDD',
      border: '#ffffff',
      required: '#ffd2d2',
    },
    drawer: {
      bgColor1: colors.primary + 'AA',
      bgColor2: colors.secondary + 'AA',
      fontColor: '#ffffff',
      fontColorSelected: '#D9D9D9',
      dividerColor: '#CCCCCC',
      selectedBackgroundCollapsedMode: 'light',
    },
    voiceQuestionInput: {
      text: '#181818',
      background: '#f5f5f5',
      inputPlaceholder: 'rgba(0,0,0,0.1)',
      iosEndColor: 'rgba(0,0,0,0)',
      mainButtonStartColor: '#181818',
      mainButtonEndColor: '#6c6c6c',
    },
    afterLogin: {
      contentBackground: '#f5f5f5',
      text: '#181818',
      label: '#5d5d5d',
      line: '#999',
      selected: 'rgba(0,0,0,0.1)',
      checkIcon: '#e7e6e6',
      checkIconBackground: colors.primary,
    },
  };
};

/**
 * The type representing the structure of the light theme.
 * @type {Theme}
 */
type Theme = ReturnType<typeof lightTheme>;

export type {Theme};
export default lightTheme;
