import {Colors} from '../interfaces/theme/colors';

/**
 * The colors object that defines the color palette for the application.
 * @type {Colors}
 */
export const colors: Colors = {
  primary: '#009F9C',
  secondary: '#007BB2',
  ripple: '#D9D9D9',
  warning: '#FFD10A',
  error: '#AC2F2F',
  success: '#1D832E',
  title: '#424242',
  description: '#707070',
  icon: '#A1A1A1',
};
