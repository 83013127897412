import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={21}
    viewBox={'0 0 23 21'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M10.645 20C5.318 20 1 15.747 1 10.5S5.318 1 10.645 1"
    />
    <path
      fill={props.fill || '#fff'}
      d="M10 9.5a1 1 0 0 0 0 2v-2Zm12.707 1.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 1 0-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM10 11.5h12v-2H10v2Z"
    />
  </svg>
);
export default SvgComponent;
