import * as React from 'react';

const SvgComponent = props => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
    fill="none"
    stroke="none">
    <path
      fill="#007BB2"
      fillOpacity={0.85}
      d="M0 8.998C.04 7.73.05 6.46.133 5.193c.04-.612.136-1.242.336-1.82.617-1.78 1.901-2.82 3.746-3.157C5.468-.014 6.737-.001 8.004 0c1.397.001 2.794.01 4.19.045.937.023 1.88.102 2.749.485 1.593.7 2.57 1.925 2.84 3.65.136.859.198 1.736.209 2.606.022 1.795 0 3.592-.03 5.387-.012.83-.09 1.662-.372 2.45-.604 1.686-1.789 2.734-3.538 3.12-1.11.246-2.244.255-3.374.256a243.18 243.18 0 0 1-4.817-.041c-.835-.016-1.673-.093-2.466-.38C1.73 16.978.695 15.8.3 14.07c-.214-.934-.235-1.889-.254-2.84-.015-.743-.003-1.486-.003-2.23H0Zm9.713.027c0-1.233.003-2.466 0-3.698-.002-.41-.3-.705-.69-.706a.673.673 0 0 0-.69.68c-.003 2.466-.004 4.931.005 7.397 0 .146.073.31.16.432a.646.646 0 0 0 .755.216c.29-.105.46-.351.46-.687.002-1.211 0-2.423 0-3.634Zm-4.148-.034v2.683c0 .476.282.794.696.79.407-.003.686-.317.686-.779V6.318a1.03 1.03 0 0 0-.025-.257.69.69 0 0 0-.748-.518.69.69 0 0 0-.608.7c-.004.916-.001 1.832-.002 2.748h.001Zm5.532-.01c0 .915 0 1.832-.002 2.747 0 .248.085.453.286.6a.642.642 0 0 0 .712.061c.27-.139.387-.37.387-.671-.002-1.804 0-3.607-.001-5.411 0-.456-.29-.771-.697-.768-.409.004-.685.316-.685.779-.002.887 0 1.775 0 2.662Zm-8.298.026c0 .296-.003.591 0 .886.005.42.3.722.697.72.389-.003.681-.3.685-.71.004-.597.004-1.195 0-1.793-.003-.422-.298-.722-.697-.719-.397.003-.68.303-.684.73-.003.296 0 .592 0 .886Zm12.446.016c0-.31.004-.62-.001-.929-.007-.407-.301-.703-.691-.703-.392 0-.686.294-.689.704-.005.605-.005 1.21 0 1.815.003.405.301.703.69.703.39 0 .683-.296.69-.703.005-.296.002-.591.002-.886l-.001-.001Z"
    />
  </svg>
);

export default SvgComponent;
