import {FontItem, FontStorageState} from '../../interfaces/screens/fontChanger';

const isSmallHeight = window.innerHeight < 900;

export const fontSizeOptions: Array<FontItem> = [
  {
    value: 1,
    label: isSmallHeight ? '12' : '14',
    textSize: isSmallHeight ? 12 : 14,
  },
  {
    value: 2,
    label: isSmallHeight ? '15' : '17',
    textSize: isSmallHeight ? 15 : 17,
  },
  {
    value: 3,
    label: isSmallHeight ? '18' : '20',
    textSize: isSmallHeight ? 18 : 20,
  },
  {
    value: 4,
    label: isSmallHeight ? '22' : '24',
    textSize: isSmallHeight ? 22 : 24,
  },
];

export const initialFontReducer: FontStorageState = {
  id: fontSizeOptions[1].label,
  item: fontSizeOptions[1],
  fontSize: fontSizeOptions[1]?.textSize,
  fontWeight: 'normal',
};
