import React, {useCallback} from 'react';
import styled, {useTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {Theme} from '../../theme/lightTheme';
import {LanguageListItemProps} from '../../interfaces/components/languageListItem';
import MyPressable from '../myPressable';
import {IoMdCheckmarkCircleOutline} from 'react-icons/io';

/**
 * Converts a country code to the corresponding flag emoji.
 *
 * @param {string} code - The country code to convert. Expected format is either 'flag-XX' or 'XX', where XX is the ISO 3166-1 alpha-2 code.
 * @returns {string} The corresponding flag emoji or an empty string if the code is invalid.
 */
const getFlagEmoji = (code: string): string => {
  try {
    const regionCode = code.replace('flag-', '').toUpperCase();
    if (regionCode.length !== 2) {
      throw new Error('Invalid country code length');
    }
    const codePoints = regionCode
      .split('')
      .map(char => 0x1f1e6 + char.charCodeAt(0) - 'A'.charCodeAt(0));

    return String.fromCodePoint(...codePoints);
  } catch (error: any) {
    console.error(
      'Error converting country code to flag emoji:',
      error.message,
    );
    return '';
  }
};

// Height calculation remains the same

const Container = styled.div`
  width: ${({theme}) => (theme.isMobile ? '100%' : '48%')};
  border-radius: 8px;
  margin-bottom: 1rem;
  background: linear-gradient(
    to left,
    ${({theme}) => theme.text.light + '11'},
    ${({theme}) => theme.text.light + '22'}
  );
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
`;

const ContentContainer = styled(MyPressable)`
  width: 94%;
  height: 100%;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.light};
  padding: 0 0.5rem;
  height: 100%;
  letter-spacing: 0.5px;
`;

const Flag = styled.span`
  font-size: ${({theme}) => theme.text.s4};
  line-height: ${({theme}) => theme.text.s4};
  width: ${({theme}) => `calc(${theme.text.s4} * 1.4)`};
`;

/**
 * Displays a Language List Item.
 * @param {boolean} isSelected Indicates whether the item is selected.
 * @param {LanguageListItemProps} props The properties of the LanguageListItem component.
 * @returns {JSX.Element} The LanguageListItem component.
 */
const LanguageListItem: React.FC<LanguageListItemProps> = ({
  onPress,
  currentValue,
  item,
  className = '',
}): JSX.Element => {
  const theme = useTheme() as Theme;

  const onPressHandler = useCallback(() => {
    onPress && onPress(item);
  }, [onPress]);

  return (
    <Container className={className}>
      <motion.div
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: 20}}>
        <ContentContainer
          disableRipple
          borderRadius={8}
          containerStyle={{
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: theme.border + '77',
          }}
          onPress={onPressHandler}>
          <Row>
            <TextContainer>
              {item.countryCode && (
                <Flag>{getFlagEmoji(item.countryCode)}</Flag>
              )}
              <Text>{item.name}</Text>
            </TextContainer>
            {currentValue === item.code && (
              <motion.div
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}>
                <IoMdCheckmarkCircleOutline
                  color={theme.primary}
                  size={theme.text.s5}
                />
              </motion.div>
            )}
          </Row>
        </ContentContainer>
      </motion.div>
    </Container>
  );
};

// Wrap with React.memo to add memoization
export default React.memo(LanguageListItem, (prevProps, nextProps) => {
  return (
    prevProps.item === nextProps.item &&
    prevProps.currentValue === nextProps.currentValue &&
    prevProps.onPress === nextProps.onPress &&
    prevProps.className === nextProps.className
  );
});
