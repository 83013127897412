import * as React from 'react';
import {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    {...props}
    fill="none"
    stroke="none">
    <g filter="url(#a)">
      <circle
        cx={22}
        cy={20}
        r={17}
        stroke="url(#b)"
        strokeOpacity={0.85}
        strokeWidth={2}
      />
    </g>
    <path
      fill="url(#c)"
      fillOpacity={0.85}
      fillRule="evenodd"
      d="M24 11h-4v7h-7v4h7v7h4v-7h7v-4h-7v-7Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="b"
        x1={20.786}
        x2={20.786}
        y1={2}
        y2={38}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={21.393}
        x2={21.393}
        y1={11}
        y2={29}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
