import React, {useEffect, useRef, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {Theme} from '../../../../theme/lightTheme';
import moment from 'moment';
import DatePicker from '../../../../components/datePicker';
import {ProfileScreenItemProps} from '../../../../interfaces/screens/profileScreen';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux/store';
import Picker from '../../../../components/picker';
import {updateUser} from '../actions';
import {convertToYYYYMMDD, getUserDetailsByIds} from '../../../../utils/helper';
import {tr} from '../../../../translation';
import {
  checkIfEmailValid,
  checkIfFormValid,
  handleChange,
} from '../../../../utils/form';
import GenericInput from '../../../../components/textInput';
import GenericButton from '../../../../components/button';
import Image from '../../../../components/Image';
import placeholderImage from '../../../../assets/images/user.png';
import {motion} from 'framer-motion';

const Container = styled.div<{fromDrawer: boolean}>`
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({theme, fromDrawer}) =>
    fromDrawer ? `${theme.dimensions.hdp(100)}px` : 'auto'};
  overflow: ${({fromDrawer}) => (fromDrawer ? `auto` : 'hidden')};
`;
const ContentContainer = styled.div<{fromDrawer: boolean}>`
  overflow-y: ${({fromDrawer}) => (fromDrawer ? `auto` : 'hidden')};
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div<{fromDrawer?: boolean}>`
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(16px);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({theme, fromDrawer}) =>
    theme?.isMobile ? '90%' : fromDrawer ? '77%' : '60%'};
  padding: 4%;
  margin: 6% auto;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 26px;

  ${({fromDrawer}) =>
    !fromDrawer &&
    css`
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(16px);
    `};
`;
const Name = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
`;
const TopContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 5% 0;
`;
const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({theme}: {theme: Theme}) => (theme.isMobile ? 5 : 7)}rem;
  aspect-ratio: 1;
  overflow: hidden;
  border-color: ${({theme}) => theme.homeBackground};
  border-width: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.85;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const InputWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    width: 48%;
  }
`;

// Define the mandatory fields for the form
const mandatoryFields = [
  'email',
  'firstName',
  'lastName',
  'gender',
  'country',
  'profession',
  'specialty',
  'bod',
];

const EditProfile: React.FC<ProfileScreenItemProps> = ({
  toggleEdit,
  fromDrawer,
  activeIndex,
}) => {
  const theme = useTheme() as Theme;
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const {currentUser, config} = useSelector(
    (state: RootState) => state.auth,
  ) || {
    currentUser: {},
  };
  const {Countries, Genders, Languages, Professions, Specialties} =
    config || {};
  const {
    gender: gender_id,
    profession_id,
    country_id,
    language_id,
    speciality_id,
    id,
    email,
    first_name,
    last_name,
    date_of_birth,
  } = currentUser || {};
  const {gender, country, profession, speciality, language} =
    getUserDetailsByIds({
      config,
      country_id,
      gender_id,
      profession_id,
      language_id,
      speciality_id,
    });

  // Define the form state
  const [form, updateForm] = useState<any>({
    email: email || '',
    firstName: first_name || '',
    lastName: last_name || '',
    bod: convertToYYYYMMDD(date_of_birth || '') ?? '',
    gender: gender || '',
    country: country || '',
    profession: profession || '',
    specialty: speciality || '',
    language: language || '',
  });

  const [isErrorEmail, setIsErrorEmail] = useState(false);
  // Define the form completion state
  const [formComplete, setFormComplete] = useState(false);
  // Define the error state
  const [isError, setIsError] = useState(false);

  // Effect to check if the form is complete
  useEffect(() => {
    const isValidEmail = checkIfEmailValid(form.email);
    setFormComplete(checkIfFormValid(form, mandatoryFields) && isValidEmail);
    setIsErrorEmail(!isValidEmail);
  }, [form]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    if (!fromDrawer) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [activeIndex]);

  // Function to handle form submission
  const submit = async () => {
    setIsError(false);

    const body: any = {
      id,
      email: form?.email || email,
      password: form?.password,
      first_name: form?.firstName || first_name,
      last_name: form?.lastName || last_name,
      date_of_birth: form?.bod
        ? moment(form?.bod).format('YYYY-MM-DD')
        : date_of_birth,
      gender: form?.gender?.[0]?.id || gender_id,
      country_id: form?.country?.[0]?.id || country_id,
      profession_id: form?.profession?.[0]?.id || profession_id,
      speciality_id: form?.specialty?.[0]?.id || speciality_id,
      language_id: 2,
    };

    const res = await updateUser(body);
    if (toggleEdit && res) {
      toggleEdit();
    }
    setIsError(!res);
  };

  const _getPickerList = (label: string): any[] => {
    switch (label) {
      case 'gender':
        return Genders || [];
      case 'language':
        return Languages || [];
      case 'profession':
        return Professions || [];
      case 'specialty':
        return Specialties || [];
      case 'country':
        return Countries || [];
      default:
        return [];
    }
  };

  const _renderItem = (label: string) => {
    if (label === 'bod') {
      return (
        <InputWrapper key={label}>
          <DatePicker
            label={tr(`register.${label}`)}
            labelStyle={{fontWeight: 'bold'}}
            placeholder={tr(`register.${label}`)}
            value={form[label]}
            onChange={date => handleChange(label, date, updateForm)}
            maxDate={new Date()}
            format={'YYYY-MM-DD'}
          />
        </InputWrapper>
      );
    }
    if (
      label === 'gender' ||
      label === 'language' ||
      label === 'profession' ||
      label === 'country' ||
      label === 'specialty'
    ) {
      return (
        <InputWrapper key={label}>
          <Picker
            placeholder={tr(`register.${label}`)}
            label={tr(`register.${label}`)}
            labelStyle={{fontWeight: 'bold'}}
            list={_getPickerList(label)}
            labelKey={'name'}
            valueKey={'id'}
            defaultValue={form?.[label]?.name || ''}
            values={form?.[label]?.name ? [form?.[label]] : []}
            onChange={values => handleChange(label, values, updateForm)}
          />
        </InputWrapper>
      );
    }
    return (
      <InputWrapper key={label}>
        <GenericInput
          placeholder={tr(`register.${label}`)}
          label={tr(`register.${label}`)}
          labelStyle={{fontWeight: 'bold'}}
          inputContainerStyle={{
            borderRadius: 100,
            borderColor: theme.text.light,
          }}
          style={{
            color: theme.text.light,
          }}
          value={form[label] || ''}
          onChange={e => handleChange(label, e.target.value, updateForm)}
          secureInput={label.toLowerCase().includes('password')}
          hasError={form?.password !== form?.confirmPassword}
          enableErrorMessage={
            label === 'confirmPassword' &&
            form.confirmPassword.length > 0 &&
            form.password.length > 0
          }
          errorMessage={'Passwords do not match.'}
          showRightIcon={label === 'email' && form.email.length > 0}
          rightIconType={'SVG'}
          rightIconName={isErrorEmail ? 'errorField' : 'check'}
          rightIconColor={
            label.toLowerCase().includes('password')
              ? theme.text.light
              : undefined
          }
        />
      </InputWrapper>
    );
  };

  return (
    <Container fromDrawer={fromDrawer}>
      <ContentContainer fromDrawer={fromDrawer} ref={scrollableDivRef}>
        <FormContainer fromDrawer={fromDrawer}>
          <TopContainer>
            <ProfileImageContainer>
              <Image
                source={currentUser?.img}
                placeholderImage={placeholderImage}
              />
            </ProfileImageContainer>
            <Name>{first_name + ' ' + last_name}</Name>
          </TopContainer>
          <InputContainer>{mandatoryFields.map(_renderItem)}</InputContainer>
          <GenericButton
            disabled={!formComplete}
            onPress={submit}
            label={tr('profile.save')}
            labelStyle={{color: theme.text.light}}
            style={{
              marginBottom: '5%',
              padding: '0 5%',
              borderColor: theme.text.light,
            }}
          />
        </FormContainer>
      </ContentContainer>
    </Container>
  );
};

export default EditProfile;
