import React from 'react';
import {useSelector} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import lightTheme from './theme/lightTheme';
import {RootState} from './redux/store';
import GlobalLoading from './connected-components/loading';
import AppRouter from './route';
import GlobalToast from './connected-components/toast';
import {I18nextProvider} from 'react-i18next';
import i18n from './translation/i18n';
import GlobalModal from './connected-components/globalModal';
import {GlobalStyle} from './styles';
import darkTheme from './theme/darkTheme';
import LanguagePickerModal from './connected-components/languagePickerModal';

const App = () => {
  const {isDarkMode} = useSelector((state: RootState) => state.settings);
  const theme = isDarkMode ? darkTheme() : lightTheme();
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppRouter />
        <GlobalToast />
        <GlobalLoading />
        <GlobalModal />
        <LanguagePickerModal />
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
