import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {ImageProps} from '../../interfaces/components/image';
import placeholderImage from '../../assets/images/placeholderImage.jpg';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background: radial-gradient(
    at top left,
    rgba(204, 204, 204, 0.3) 0%,
    rgba(204, 204, 204, 0.2) 30%,
    rgba(204, 204, 204, 0.2) 80%,
    rgba(204, 204, 204, 0.3) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${({theme}) => theme.primary};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const StyledLazyLoadImage = styled(LazyLoadImage)<{resizeMode?: any}>`
  object-fit: ${({resizeMode}) => resizeMode || 'cover'};
  width: 100%;
  height: 100%;
`;

/**
 * Image Component
 *
 * This component renders an image with a loading indicator and error handling.
 * It uses a default image if the main image fails to load.
 *
 * @component
 * @param {ImageProps} props - Component props.
 * @returns {JSX.Element} Image component.
 */
const Image: React.FC<ImageProps> = props => {
  const theme = useTheme();
  const [showDefault, setShowDefault] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setShowDefault(false);
    setLoading(false);
  }, []);

  return (
    <Container style={props.containerStyle}>
      {loading && !props.hideLoader && (
        <LoadingContainer>
          <Spinner theme={theme} />
        </LoadingContainer>
      )}
      <StyledLazyLoadImage
        src={
          showDefault || !props.source || error
            ? props?.placeholderImage || placeholderImage
            : props.source
        }
        height={'100%'}
        width={'100%'}
        alt={props.alt || 'image content'}
        resizeMode={props.resizeMode || 'cover'}
        effect="blur"
        beforeLoad={() => {
          setShowDefault(false);
          setLoading(true);
        }}
        onLoad={() => {
          setShowDefault(false);
          setLoading(false);
        }}
        onError={e => {
          setError(true);
          setShowDefault(true);
          if (process.env.REACT_APP_DEBUG === 'true') {
            console.log('Error loading image: ', e);
          }
        }}
        style={{display: loading ? 'none' : 'block'}}
      />
    </Container>
  );
};

export default Image;
