import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox={'0 0 20 20'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeWidth={2}
      d="M1 1h3v3H1zM8 1h11v3H8zM1 8h3v3H1zM8 8h11v3H8zM1 16h3v3H1zM8 16h11v3H8z"
    />
  </svg>
);
export default SvgComponent;
