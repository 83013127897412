import React from 'react';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../../theme/lightTheme';
import {RootState} from '../../../redux/store';
import {useSelector} from 'react-redux';
import {showGlobalModal} from '../../../connected-components/globalModal/actions';
import {tr} from '../../../translation';
import {SpecialityType} from '../../../interfaces/api/auth/login';
import GenericButton from '../../../components/button';
import {updateUser} from '../profile/actions';
import {AnimatedDiv, BlurContainer} from '../news';
import ErrorView from '../../../components/errorView';
import {updateConfig} from '../../auth/login/actions';
import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4%;
  margin-bottom: 5%;
  margin-top: 5%;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  width: ${({theme}) => (theme?.isMobile ? '90%' : '55%')};
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  border-radius: 26px;
  overflow-y: auto;
  max-height: ${({theme}: {theme: Theme}) => theme.dimensions.hdp(85)}px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 95%;
  align-self: center;
  @media (min-width: 768px) {
    width: 75%;
  }
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s6};
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 10%;
`;
const AnimatedDivView = styled(AnimatedDiv)<{isZoomedIn?: boolean}>`
  width: 100%;
`;

/**
 * ChooseYourSpeciality screen component.
 *
 */
const ChooseYourSpeciality: React.FC<any> = () => {
  const theme = useTheme() as Theme;
  const {currentUser, config: userConfig} = useSelector(
    (state: RootState) => state.auth,
  );

  const onItemClicked = async (item: any) => {
    const {
      id,
      email,
      country_id,
      date_of_birth,
      first_name,
      gender,
      last_name,
      language_id,
      profession_id,
    } = currentUser || {};
    const body: any = {
      id,
      speciality_id: item?.id,
      email,
      country_id,
      date_of_birth,
      first_name,
      gender,
      last_name,
      language_id,
      profession_id,
    };
    updateUser(body);
  };
  const _renderItem = (item: SpecialityType, index: number) => {
    const isSelected = currentUser?.speciality_id === item?.id;
    return (
      <GenericButton
        key={item?.name || index}
        disabledNoOpacity={isSelected}
        label={item.name || ''}
        labelStyle={{
          color: theme.text.light,
        }}
        style={{
          width: '90%',
          marginBottom: '4%',
          borderColor: isSelected ? theme.secondary : theme.text.light,
          backgroundColor: isSelected ? theme.text.light + 'EE' : 'transparent',
          boxShadow: isSelected ? '0 4px 10px rgba(0, 0, 0, 0.4)' : undefined,
        }}
        onPress={() =>
          showGlobalModal({
            title: tr('chooseYourSpeciality.confirmTitle'),
            message:
              tr('chooseYourSpeciality.confirmMessage') + ` ${item?.name} ?`,
            onConfirm: () => onItemClicked(item),
            type: 'question',
          })
        }
        outline
        isLabelGradient={isSelected}
        radius={100}
      />
    );
  };

  const handleRefresh = async () => {
    startLoading();
    await updateConfig();
    finishLoading();
  };

  return (
    <Container>
      {(userConfig?.Specialties || []).length !== 0 && (
        <FormContainer>
          <Title>{tr('chooseYourSpeciality.title')}</Title>
          <InputContainer>
            {(userConfig?.Specialties || []).map(_renderItem)}
          </InputContainer>
        </FormContainer>
      )}
      <AnimatedDivView
        isZoomedIn={(userConfig?.Specialties || []).length === 0}>
        <BlurContainer style={{minWidth: '40%', aspectRatio: 1}}>
          <ErrorView onRefresh={() => handleRefresh()} />
        </BlurContainer>
      </AnimatedDivView>
    </Container>
  );
};

export default ChooseYourSpeciality;
