import React, {useEffect, useRef, useState} from 'react';
import styled, {useTheme, keyframes} from 'styled-components';
import Icon from '../icon';
import {GenericInputProps} from '../../interfaces/components/textInput';
import {Theme} from '../../theme/lightTheme';
import {IoEyeOff, IoEyeOutline} from 'react-icons/io5';
import {isSmallHeight} from '../../utils/responsive';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label<any>`
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.text.s8};
  margin-bottom: 8px;
  color: ${({theme}) => theme.text.light};
  ${({labelStyle}) => labelStyle && {...labelStyle}}

  &::after {
    content: ${({isRequired}) => (isRequired ? "'*'" : "''")};
    color: ${({theme}) => theme.text.error};
    margin-left: 4px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${isSmallHeight ? 37 : 42}px;
  position: relative;
  border: 1px solid ${({theme}) => theme.textInput.border};
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme.border + '11'};
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;

  &:focus-within {
    border-color: ${({theme}) => theme.textInput.border + '99'};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const Input = styled.input<{isRightIcon:boolean}>`
  padding: 0 11px;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  border: none;
  outline: none;
  width: ${({isRightIcon})=>isRightIcon?90:100}%;
  background: transparent;
  transition: padding 0.3s ease;
  overflow: hidden;
  border-radius: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
  &::placeholder {
    color: ${({theme}) => theme.textInput.placeholder};
    opacity: ${({value}) => (value ? 0.5 : 1)};
    transition: opacity 0.3s ease;
  }

  &:focus {
    padding: 12px;
  }
`;
const MultiLineInput = styled.textarea`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  transition: padding 0.3s ease;
  border-radius: 8px;
  padding: ${({theme}) => theme.text.s8};
  resize: none;
  overflow: auto;
  margin-bottom: 2rem;
  &::placeholder {
    color: ${({theme}) => theme.textInput.placeholder};
    opacity: ${({value}) => (value ? 0.5 : 1)};
    transition: opacity 0.3s ease;
  }
`;

const ErrorContainer = styled.div<any>`
  width: 100%;
  opacity: ${({opacity}) => opacity};
  animation: ${({opacity}) => opacity === 1 && fadeIn} 0.3s ease-in-out;
  margin-top: 3px;
  padding: 0 2%;
  position: absolute;
`;

const ErrorMessage = styled.span<any>`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme, hasError, secondColor}) =>
    hasError ? theme.text.error : secondColor || theme.text.grey};
`;

const RightIconContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * GenericInput component for rendering an input field with optional features like labels, icons, and error messages.
 *
 * @component
 * @param labelStyle
 * @param secureInput
 * @param label
 * @param showRightIcon
 * @param rightIconName
 * @param rightIconType
 * @param hasError
 * @param errorMessage
 * @param rightIconSize
 * @param rightIconColor
 * @param onRightIconPress
 * @param enableErrorMessage
 * @param alwaysShowErrorMessage
 * @param errorStyle
 * @param isRequired
 * @param containerStyle
 * @param inputContainerStyle
 * @param placeholderComponent
 * @param value
 * @param multiline
 * @param className
 * @param isUserTyping
 * @param {GenericInputProps} props - The properties for the GenericInput component.
 * @returns {JSX.Element} - The rendered input component.
 */
const GenericInput: React.FC<GenericInputProps> = ({
  label,
  rightIconColor,
  isRequired,
  containerStyle,
  inputContainerStyle,
  placeholderComponent,
  labelStyle = {},
  secureInput = false,
  showRightIcon = false,
  rightIconName = '',
  rightIconType = '',
  rightIconSize = 0,
  onRightIconPress = undefined,
  value = '',
  hasError = false,
  errorMessage = '',
  enableErrorMessage = false,
  alwaysShowErrorMessage = false,
  errorStyle = {},
  multiline = false,
  className,
  isUserTyping,
  ...props
}): JSX.Element => {
  const theme = useTheme() as Theme;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(hasError ? 1 : 0);
  useEffect(() => {
    setOpacity(hasError ? 1 : 0);
  }, [hasError]);

  useEffect(() => {
    if (!isUserTyping && textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [value]);

  const renderRightIcon = () => {
    if (secureInput) {
      return (
        <RightIconContainer
          style={{right: '1rem'}}
          onClick={() => setPasswordVisible(!passwordVisible)}>
          {passwordVisible ? (
            <IoEyeOutline color={rightIconColor || theme.text.light} />
          ) : (
            <IoEyeOff color={rightIconColor || theme.text.light} />
          )}
        </RightIconContainer>
      );
    } else if (showRightIcon && rightIconName && rightIconType) {
      return (
        <RightIconContainer style={{right: '1rem'}}>
          <Icon
            type={rightIconType}
            role={onRightIconPress ? 'button' : ''}
            onPress={onRightIconPress}
            name={rightIconName}
            size={rightIconSize || theme.text.s55}
            color={rightIconColor || theme.text.light}
          />
        </RightIconContainer>
      );
    }
    return null;
  };

  return (
    <Container className={className} style={containerStyle}>
      {label && (
        <Label bold labelStyle={labelStyle} isRequired={isRequired}>
          {label}
        </Label>
      )}
      <InputContainer className={'input-container'} style={inputContainerStyle}>
        {!value && !!placeholderComponent && placeholderComponent()}
        {multiline ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <MultiLineInput
            ref={textareaRef}
            {...props}
            className={'input'}
            value={value}
            style={{
              ...props.style,
            }}
            rows={isSmallHeight || theme.isMobile ? 3 : 5}
            cols={isSmallHeight || theme.isMobile ? 40 : 50}
          />
        ) : (
          <Input
            {...props}
            className={'input'}
            value={value}
            isRightIcon={secureInput||showRightIcon}
            style={{
              ...props.style,
            }}
            type={secureInput && !passwordVisible ? 'password' : 'text'}
          />
        )}
        {renderRightIcon()}
      </InputContainer>
      {((enableErrorMessage && hasError) || alwaysShowErrorMessage) && (
        <ErrorContainer style={errorStyle} opacity={opacity}>
          <ErrorMessage hasError={hasError}>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default GenericInput;
