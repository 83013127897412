import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div)``;

type Props = {
  children: ReactNode;
  className?: string;
};

const variants = {
  initial: {
    scale: 0.98,
    y: 10,
  },
  enter: {
    scale: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 15,
      duration: 0.4,
    },
  },
};

const AnimatedRouterLayout = ({
  children,
  className,
}: Props): React.JSX.Element => {
  return (
    <Container
      className={className}
      initial="initial"
      animate="enter"
      variants={variants}
      transition={{type: 'spring'}}>
      {children}
    </Container>
  );
};

export default AnimatedRouterLayout;
