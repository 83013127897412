import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SettingsState} from '../../../interfaces/redux/settings';

const initialState: SettingsState = {
  isDarkMode: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setDark: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setDark} = settingsSlice.actions;

export default settingsSlice.reducer;
