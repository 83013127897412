import http from '../http';
import {api, baseDashboardUrl} from '../api';
import {
  GetDomainsResponse,
  GetSpecialityResponse,
  GetTypeOfStudyResponse,
  ScrapeBody,
  SpecialityBody,
} from '../../interfaces/api/dashboard/scrapeArticle';
import {BaseApiResponse} from '../../interfaces/api/baseApiResponse';

export const dashboardHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const dashboard = {
  getDomains: (): Promise<GetDomainsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.dashboard.domains, {
          baseURL: baseDashboardUrl,
          headers: dashboardHeaders,
        })
        .then(res => resolve(res as GetDomainsResponse))
        .catch(e => reject(e));
    }),
  getSpeciality: (body: SpecialityBody): Promise<GetSpecialityResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.dashboard.speciality}?term=${body?.term || ''}`;
      const data = JSON.stringify(body || {});
      http
        .get(url, {
          data,
          headers: dashboardHeaders,
          baseURL: baseDashboardUrl,
        })
        .then(res => resolve(res as GetSpecialityResponse))
        .catch(e => reject(e));
    }),
  getTypeOfStudy: (body: SpecialityBody): Promise<GetTypeOfStudyResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.dashboard.typeOfStudy}?term=${body?.term || ''}`;
      const data = JSON.stringify(body || {});
      http
        .get(url, {
          data,
          headers: dashboardHeaders,
          baseURL: baseDashboardUrl,
        })
        .then(res => resolve(res as GetTypeOfStudyResponse))
        .catch(e => reject(e));
    }),
  scrapeArticles: (body: ScrapeBody): Promise<BaseApiResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.dashboard.scrape, body, undefined, false, {
          baseURL: baseDashboardUrl,
          headers: dashboardHeaders,
        })
        .then(res => resolve(res as BaseApiResponse))
        .catch(e => reject(e));
    }),
};
