import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={466}
    height={466}
    viewBox="0 0 466 466"
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#0F1729'}
      fillRule="evenodd"
      d="M9.43 9.43c13.017-13.018 34.123-13.018 47.14 0L233 185.86 409.43 9.43c13.016-13.018 34.123-13.018 47.14 0 13.016 13.017 13.016 34.123 0 47.14L280.14 233l176.43 176.43c13.016 13.016 13.016 34.123 0 47.14-13.017 13.016-34.124 13.016-47.14 0L233 280.14 56.57 456.57c-13.017 13.016-34.123 13.016-47.14 0-13.018-13.017-13.018-34.124 0-47.14L185.86 233 9.43 56.57c-13.018-13.017-13.018-34.123 0-47.14Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
