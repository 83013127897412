import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    viewBox={'0 0 21 21'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#fff'}
      d="M4.131 18.743a.375.375 0 0 1-.236-.086A10.496 10.496 0 0 1 10.504 0c1.605-.005 3.19.362 4.63 1.072a.375.375 0 0 1-.047.693.375.375 0 0 1-.287-.018A9.746 9.746 0 0 0 4.375 18.072a.375.375 0 0 1 .053.528.374.374 0 0 1-.297.143Zm6.373 2.249a10.402 10.402 0 0 1-4.813-1.166.375.375 0 1 1 .345-.667A9.747 9.747 0 0 0 18.83 5.428a9.845 9.845 0 0 0-2.19-2.504.375.375 0 0 1 .473-.581 10.496 10.496 0 0 1-6.609 18.65Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M10.13 20.617a.374.374 0 0 1-.252-.097 11.437 11.437 0 0 1-3.123-4.633 14.665 14.665 0 0 1-1.124-5.391 13.645 13.645 0 0 1 1.124-5.394A13.382 13.382 0 0 1 9.878.472a.375.375 0 0 1 .502.555 12.659 12.659 0 0 0-2.954 4.367 12.944 12.944 0 0 0-1.045 5.102 13.87 13.87 0 0 0 1.05 5.106 10.74 10.74 0 0 0 2.95 4.363.375.375 0 0 1-.252.652Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M10.879 20.617a.375.375 0 0 1-.252-.652 10.739 10.739 0 0 0 2.95-4.363 13.872 13.872 0 0 0 1.05-5.106c0-1.754-.355-3.49-1.046-5.102a12.657 12.657 0 0 0-2.954-4.367.375.375 0 1 1 .503-.555 13.384 13.384 0 0 1 3.141 4.63 13.646 13.646 0 0 1 1.106 5.394 14.664 14.664 0 0 1-1.125 5.39 11.437 11.437 0 0 1-3.137 4.634.375.375 0 0 1-.236.097Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M20.625 10.87H.383a.375.375 0 0 1 0-.749h20.242a.375.375 0 0 1 0 .75Zm-1.5-5.247H1.883a.375.375 0 0 1 0-.75h17.243a.375.375 0 0 1 0 .75Zm0 10.496H1.883a.375.375 0 0 1 0-.75h17.243a.375.375 0 0 1 0 .75Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M10.504 20.617a.375.375 0 0 1-.375-.375V.75a.375.375 0 0 1 .75 0v19.492a.375.375 0 0 1-.375.375Z"
    />
  </svg>
);
export default SvgComponent;
