import {store} from '../../redux/store';
import {startGlobalLoading, finishGlobalLoading} from './reducer';

export const startLoading = () => {
  store.dispatch(startGlobalLoading());
};

/**
 * This function close global Loading
 */
export const finishLoading = () => {
  store.dispatch(finishGlobalLoading());
};
