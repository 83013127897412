import React, {useState, useRef, useEffect} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {MdOutlineEdit, MdOutlineEditOff} from 'react-icons/md';
import {Theme} from '../../../theme/lightTheme';
import ViewProfile from './viewProfile';
import {ProfileScreenProps} from '../../../interfaces/screens/profileScreen';
import EditProfile from './editProfile';
import {motion} from 'framer-motion';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;
const ContentContainer = styled(AnimatedRouterLayout)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: ${({theme}) => theme.dimensions.wdp(100)};
  overflow: hidden;
`;
const EditIconContainer = styled.div<{fromDrawer: boolean}>`
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0.74rem;
  aspect-ratio: 1;
  top: 1rem;
  right: 4.5rem;
  background-color: ${({theme}) => theme.text.light + '22'};
  border-radius: 50%;
  &:hover {
    opacity: 0.95;
  }
  &:active {
    opacity: 0.9;
    scale: 0.9;
  }
  ${({fromDrawer}) =>
    !fromDrawer &&
    css`
      position: absolute;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(16px);
      box-shadow: 0 2px 6px ${({theme}) => theme.shadow};
      background: linear-gradient(
        360deg,
        ${({theme}) => theme.secondary + '44'} -68.52%,
        ${({theme}) => theme.primary + '44'} 122.02%
      );
    `}
`;
const ScreenContent = styled.div`
  white-space: nowrap;
  height: 100%;
`;
const Screen = styled(motion.div)<{containerWidth?: number}>`
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
`;

const ProfilePage: React.FC<ProfileScreenProps> = ({fromDrawer}) => {
  useSetMainLayoutProps(
    fromDrawer
      ? null
      : {
          showHeader: true,
          showSearch: false,
        },
  );
  const theme = useTheme() as Theme;
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, containerDivWidth] = useState<number>(0); // State to store the width
  const profileTabsVariantsTab1 = {
    hidden: {x: -containerWidth},
    enter: {x: 0},
  };
  const profileTabsVariantsTab2 = {
    hidden: {x: 0},
    enter: {x: -containerWidth},
  };
  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      containerDivWidth(width);
    }
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.getBoundingClientRect().width;
        containerDivWidth(width);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleEdit = () => {
    const newIndex = activeIndex === 0 ? 1 : 0;
    setActiveIndex(newIndex);
  };

  const subScreenProps = {fromDrawer, toggleEdit, activeIndex};
  const _renderScreen = () => {
    return (
      <Container ref={containerRef}>
        <EditIconContainer fromDrawer={fromDrawer} onClick={toggleEdit}>
          {activeIndex === 0 ? (
            <MdOutlineEdit color={theme.text.light} size={theme.text.s55} />
          ) : (
            <MdOutlineEditOff color={theme.text.light} size={theme.text.s55} />
          )}
        </EditIconContainer>
        <ScreenContent>
          {fromDrawer ? (
            <>
              <Screen
                key={'1'}
                initial="enter"
                animate={activeIndex === 0 ? 'enter' : 'hidden'}
                variants={profileTabsVariantsTab1}
                transition={{duration: 0.3}}>
                <ViewProfile {...subScreenProps} />
              </Screen>
              <Screen
                key={'2'}
                initial="enter"
                animate={activeIndex === 1 ? 'enter' : 'hidden'}
                variants={profileTabsVariantsTab2}
                transition={{duration: 0.3}}>
                <EditProfile {...subScreenProps} />
              </Screen>
            </>
          ) : (
            <>
              {activeIndex === 0 ? (
                <ViewProfile {...subScreenProps} />
              ) : (
                <EditProfile {...subScreenProps} />
              )}
            </>
          )}
        </ScreenContent>
      </Container>
    );
  };

  if (!fromDrawer) {
    return <ContentContainer>{_renderScreen()}</ContentContainer>;
  }
  return _renderScreen();
};

export default ProfilePage;
