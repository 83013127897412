import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ToastMessage,
  ToastState,
} from '../../interfaces/connected-components/toast';

/**
 * Represents the initial state for the toast slice.
 * @type {ToastState}
 */
const initialState: ToastState = {
  isVisible: false,
  message: '',
  type: 'info',
};

/**
 * Represents the toast slice.
 * @type {Slice}
 */
export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    /**
     * Action reducer that shows the toast with the specified message.
     * @param {ToastState} state - The current state of the toast.
     * @param {PayloadAction<{ message: string }>} action - The action containing the message payload.
     * @returns {void}
     */
    showToast: (state, action: PayloadAction<ToastMessage>): void => {
      state.message = action.payload.message;
      state.type = action.payload.type || 'info';
      state.isVisible = true;
    },

    /**
     * Action reducer that hides the toast.
     * @param {ToastState} state - The current state of the toast.
     * @returns {void}
     */
    hideToast: (state): void => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function

/**
 * Action creator that triggers the showToast action.
 * @type {ActionCreatorWithPayload<{ message: string }, string>}
 */
export const {showToast, hideToast} = toastSlice.actions;

/**
 * The reducer function for the toast slice.
 * @type {Reducer<ToastState>}
 */
export default toastSlice.reducer;
