import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {closeGlobalModal} from './actions';
import {RootState} from '../../redux/store';
import {Theme} from '../../theme/lightTheme';
import {tr} from '../../translation';
import {ModalProps} from '../../interfaces/connected-components/globalModal';

const hide = keyframes`
    from {
        opacity: 1;
        transform:  translateY(0px);
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
    to {
        opacity: 0;
        transform:  translateY(50px);
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
`;

const show = keyframes`
    from {
        opacity: 0;
        transform:  translateY(50px);
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    to {
        opacity: 1;
        transform:  translateY(0px);
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
`;

const Container = styled.div<{isVisible: boolean}>`
  width: 100vw;
  height: 100vh;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  pointer-events: ${({isVisible}) => (isVisible ? 'auto' : 'none')};
  position: absolute;
  top: 0;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;
const ContentContainer = styled.div<{isVisible: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.text.light};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 999;
  overflow: hidden;
  ${({isVisible}) =>
    isVisible
      ? css`
          animation: ${show} 0.2s ease-in-out;
          opacity: 1;
        `
      : css`
          animation: ${hide} 0.2s ease-in-out;
          pointer-events: none;
          opacity: 0;
        `};
  width: 80%;
  max-width: 600px;
  min-width: 300px;
  min-height: 10rem;

  @media (min-width: 768px) {
    width: 60%;
  }
  @media (min-width: 1024px) {
    width: 30%;
  }
`;

const CustomButton = styled.button<any>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  ${({withBorder, theme}) =>
    withBorder &&
    `
    border-right: 1px solid ${theme.text.border};
    `};
  &:hover {
    background-color: ${({theme}) => theme.text.border + '77'};
  }
  &:active {
    background-color: ${({theme}) => theme.text.border};
  }
`;

const Label = styled.p`
  color: ${({theme}) => theme.primary};
  font-size: ${({theme}) => theme.text.s9};
  text-align: center;
  font-weight: bold;
`;

const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s7};
  text-align: center;
  font-weight: bold;
`;

const Message = styled.p<{withPaddingTop: boolean}>`
  width: 90%;
  flex: 1;
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s8};
  text-align: center;
  padding-top: ${({withPaddingTop}) => (withPaddingTop ? '3%' : '0')};
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;
const CustomButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({theme}) => theme.border};
  margin-top: auto;
`;
const Overlay = styled.div<{isVisible: boolean}>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 150vh;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s ease-in-out;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  ${({isVisible}) =>
    !isVisible &&
    `
      pointer-events:none;
    `};
`;

const GlobalModal = ({globalModal}: ModalProps) => {
  const theme = useTheme() as Theme;
  const {
    message,
    title,
    isVisible,
    type,
    onConfirm,
    onReject,
    customTopComponent,
    confirmText,
    rejectText,
  } = globalModal || {};
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (isVisible) {
      setIsModalVisible(true);
    } else {
      timeout = setTimeout(() => setIsModalVisible(false), 200);
    }
    document.body.style.overflow = isVisible ? 'hidden' : '';
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isVisible]);

  const closeModal = (status: 'confirm' | 'reject' = 'confirm') => {
    if (status === 'confirm') {
      if (onConfirm) {
        onConfirm();
      }
    } else {
      if (onReject) {
        onReject();
      }
    }
    closeGlobalModal();
  };

  return (
    <Container isVisible={isModalVisible}>
      <Overlay
        onClick={() => closeModal('reject')}
        isVisible={isModalVisible}
      />
      <ContentContainer isVisible={isVisible}>
        <TopContainer>
          {!!customTopComponent && customTopComponent()}
          {!!title && <Title>{title}</Title>}
          <Message withPaddingTop={!!title || !!customTopComponent}>
            {message}
          </Message>
        </TopContainer>
        <CustomButtonsContainer>
          {type === 'question' && (
            <CustomButton withBorder onClick={() => closeModal('reject')}>
              <Label style={{color: theme.text.grey}}>
                {rejectText || tr('app.cancel')}
              </Label>
            </CustomButton>
          )}
          <CustomButton onClick={() => closeModal()}>
            <Label>
              {confirmText
                ? confirmText
                : type === 'question'
                  ? tr('app.yes')
                  : tr('app.ok')}
            </Label>
          </CustomButton>
        </CustomButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  globalModal: state.globalModal,
});

export default connect(mapStateToProps, null)(GlobalModal);
