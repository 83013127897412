import React from 'react';

const SvgComponent = props => (
  <svg
    width={20}
    height={23}
    viewBox="0 0 20 23"
    {...props}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="url(#a)"
      fillOpacity={0.85}
      d="M18.967 17.924c-.348.803-.76 1.543-1.236 2.223-.65.927-1.183 1.569-1.593 1.925-.636.585-1.318.885-2.047.902-.524 0-1.156-.15-1.891-.452-.738-.3-1.416-.45-2.036-.45-.65 0-1.348.15-2.094.45-.747.303-1.349.46-1.809.476-.7.03-1.397-.278-2.093-.926-.445-.387-1-1.052-1.666-1.993-.715-1.005-1.302-2.17-1.762-3.5C.247 15.144 0 13.755 0 12.41c0-1.542.333-2.871 1-3.985a5.867 5.867 0 0 1 2.095-2.12 5.635 5.635 0 0 1 2.832-.798c.556 0 1.285.172 2.19.51.904.339 1.484.51 1.738.51.19 0 .834-.2 1.926-.601 1.033-.372 1.905-.526 2.62-.465 1.934.156 3.388.919 4.355 2.293-1.73 1.049-2.587 2.517-2.57 4.401.016 1.468.548 2.69 1.594 3.659.475.45 1.004.798 1.593 1.045-.127.37-.262.725-.406 1.066ZM14.53.46c0 1.15-.42 2.224-1.258 3.218-1.01 1.182-2.233 1.865-3.559 1.757A3.582 3.582 0 0 1 9.685 5c0-1.105.481-2.287 1.335-3.253.426-.489.968-.896 1.625-1.22.656-.32 1.277-.497 1.86-.527.017.154.024.308.024.46Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={9.033}
        x2={9.033}
        y1={0}
        y2={23}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
