import {GetTabNewsData, SearchNewsForm} from '../../../interfaces/api/tabs';
import {services} from '../../../api';
import {showGlobalToast} from '../../../connected-components/toast/actions';

/**
 * this function to get news List as search results
 *
 * @return {GetTabNewsData} result of news List
 */
export const searchNews = async (
  body: SearchNewsForm,
): Promise<GetTabNewsData | null> => {
  try {
    const res = await services.news.search(body);
    if (Array.isArray(res?.data)) {
      const tempRes: any = {
        totalCount: (res?.data || []).length,
        news: res?.data,
        message: '',
        size: (res?.data || []).length,
      };
      return {...tempRes, message: res?.message} || {};
    }
    if (res?.data?.news?.length === 0 && body.page === 1) {
      showGlobalToast({message: res?.message, type: 'info'});
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return {...res?.data, message: res?.message} || {};
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Search News FAIL: ', error);
    }
    return null;
  }
};
