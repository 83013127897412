import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox={'0 0 20 20'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeWidth={2}
      d="M1 1h7.333v7.333H1zM11.666 1h7.333v7.333h-7.333zM1 11.667h7.333V19H1zM11.666 11.667h7.333V19h-7.333z"
    />
  </svg>
);
export default SvgComponent;
