import * as React from 'react';

const SvgComponent = props => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    {...props}
    fill="none"
    stroke="none">
    <g filter="url(#a)">
      <path
        fill="url(#b)"
        fillOpacity={0.85}
        d="M1.008 10.498c.04-1.269.05-2.539.131-3.804.04-.613.136-1.243.336-1.82.617-1.78 1.902-2.82 3.746-3.158 1.254-.23 2.524-.217 3.79-.215 1.397.001 2.794.01 4.19.045.938.023 1.88.102 2.75.485 1.592.701 2.57 1.925 2.84 3.65.135.86.197 1.736.208 2.606.022 1.795 0 3.592-.029 5.388-.013.83-.09 1.661-.373 2.449-.603 1.686-1.788 2.734-3.537 3.12-1.112.246-2.245.255-3.375.256-1.606.002-3.212-.011-4.817-.041-.835-.016-1.673-.092-2.465-.38-1.666-.602-2.701-1.78-3.096-3.51-.213-.934-.235-1.889-.254-2.84-.015-.743-.003-1.486-.003-2.229l-.042-.002Zm6.564-.007v2.683c0 .476.282.794.697.79.406-.003.685-.317.685-.779V7.818a1.03 1.03 0 0 0-.025-.257.69.69 0 0 0-.747-.518.69.69 0 0 0-.609.7c-.004.916 0 1.832 0 2.748Zm3.532-.01-.001 2.748c-.001.248.084.452.285.599a.642.642 0 0 0 .712.061c.27-.139.387-.37.387-.671-.002-1.804 0-3.607-.001-5.41 0-.457-.29-.772-.697-.769-.409.004-.685.316-.685.78v2.661Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={9.401}
        x2={9.401}
        y1={1.5}
        y2={19.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
