import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  FiltersModalMessage,
  FiltersModalState,
} from '../../interfaces/connected-components/filtersModal';

const initialState: FiltersModalState = {
  isVisible: false,
  onSubmit: null,
  filters: {},
  filtersValues: {},
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openFiltersModal: (state, action: PayloadAction<FiltersModalMessage>) => {
      state.isVisible = true;
      state.onSubmit = action.payload.onSubmit;
      state.filters = action.payload.filters;
      state.filtersValues = action.payload.filtersValues;
    },
    hideFiltersModal: state => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {openFiltersModal, hideFiltersModal} = modalSlice.actions;

export default modalSlice.reducer;
