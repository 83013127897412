import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {connect} from 'react-redux';
import {RootState} from '../../redux/store';
import {LoadingState} from '../../interfaces/connected-components/loading';
import {tr} from '../../translation';

const Container = styled.div<{isVisible: boolean}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  pointer-events: ${({isVisible}) => (isVisible ? 'auto' : 'none')};
  background-color: ${({theme}) => theme.overlay};
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
`;
const Loading = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
  margin-top: ${({theme}) => theme.text.s7};
  letter-spacing: 2px;
  padding-right: 5%;
  font-weight: bold;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const bounceAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;

const BouncingLoader = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    width: ${({theme}) => theme.text.s11};
    height: ${({theme}) => theme.text.s11};
    margin: 3px 6px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.border};
    opacity: 1;
    animation: ${bounceAnimation} 0.6s infinite alternate;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const GlobalLoading = ({loading}: {loading: LoadingState}) => {
  const {isVisible} = loading || {};
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (isVisible) {
      setIsModalVisible(true);
    } else {
      timeout = setTimeout(() => setIsModalVisible(false), 300);
    }
    document.body.style.overflow = isVisible ? 'hidden' : '';
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isVisible]);

  if (!isModalVisible) return null;

  return (
    <Container isVisible={isVisible}>
      <Row>
        <Loading>{tr('app.loading')}</Loading>
        <BouncingLoader>
          <div></div>
          <div></div>
          <div></div>
        </BouncingLoader>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state?.loading,
  };
};
export default connect(mapStateToProps)(GlobalLoading);
