import {UpdateUserBody} from '../../../interfaces/api/auth/login';
import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';
import {services} from '../../../api';
import {startSession} from '../../auth/login/actions';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {store} from '../../../redux/store';
import {tr} from '../../../translation';
import {resetTabsVoice} from '../../auth/login/reducer';

/**
 * this function to Update User details
 */
export const updateUser = async (body: UpdateUserBody): Promise<boolean> => {
  try {
    startLoading();
    const {currentUser} = store.getState().auth || {};
    const res = await services.auth.updateUser(body);

    showGlobalToast({
      message: tr('profile.profileUpdateSuccess'),
      type: 'success',
    });
    const tempRes: any = {...(res?.data || {})};

    if (
      !!(tempRes?.voice_url || tempRes?.voiceUrl) &&
      currentUser?.voiceUrl !== (tempRes?.voice_url || tempRes?.voiceUrl)
    ) {
      store.dispatch(resetTabsVoice());
    }

    const tempCurrentUser = {
      ...currentUser,
      ...(tempRes || {}),
      voiceUrl:
        tempRes?.voice_url || tempRes?.voiceUrl || currentUser?.voiceUrl,
    };

    await startSession(tempCurrentUser);
    return true;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('UPDATE USER FAIL: ', error);
    }
    return false;
  } finally {
    finishLoading();
  }
};
