import {StackItem} from '../interfaces/router/stackItem';
import {Route, Navigate} from 'react-router-dom';
import React from 'react';
import Paths from '../config/paths';

/**
 * Create the router from the provided list of stack items and modal stack items.
 *
 * @param {StackItem[]} stacksList - The list of stack items representing the main screens.
 * @param protectedRedirectPath
 * @returns {JSX.Element} The JSX element representing the router.
 */
export const getStacksGroups = (
  stacksList: StackItem[],
  protectedRedirectPath?: string,
): JSX.Element => {
  return (
    <React.Fragment>
      {stacksList.map((screen, idx) => (
        <Route
          key={`screen${idx}`}
          index={screen.path === Paths.content.home}
          path={screen.path === Paths.content.home ? undefined : screen.path}
          element={
            !!protectedRedirectPath ? (
              <Navigate to={protectedRedirectPath} />
            ) : (
              <screen.component />
            )
          }
        />
      ))}
    </React.Fragment>
  );
};
