import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props} fill={'none'} stroke={'none'}>
    <path
      fill={props.fill || '#ffffff'}
      d="M14 10H3v2h11v-2m0-4H3v2h11V6M3 16h7v-2H3v2m11.4 6 2.6-2.6 2.6 2.6 1.4-1.4-2.6-2.6 2.6-2.6-1.4-1.4-2.6 2.6-2.6-2.6-1.4 1.4 2.6 2.6-2.6 2.6 1.4 1.4Z"
    />
  </svg>
);
export default SvgComponent;
