const keyName = process.env.REACT_APP_KEY_NAME;

export const keys = {
  CURRENT_USER: `${keyName}_CURRENT_USER`,
  FAVORITE: `${keyName}_FAVORITE`,
  NOTES: `${keyName}_NOTES`,
  LANG: `${keyName}_LANGUAGE`,
  DARK_MODE: `${keyName}_DARK_MODE`,
  FONT: `${keyName}_FONT`,
  INSTRUCTIONS: `${keyName}_INSTRUCTIONS`,
  LAST_LOGIN: `${keyName}_LAST_LOGIN`,
};
