import {createGlobalStyle} from 'styled-components';
import {getFontFamily} from './translation/actions';

export const GlobalStyle = createGlobalStyle`
   @font-face {
     font-family: 'Helvetica';
     src: url('/fonts/Helvetica-Regular.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
     font-display: fallback;
   }

   @font-face {
     font-family: 'Helvetica';
     src: url('/fonts/HelveticaNeue.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
     font-display: fallback;
   }

   @font-face {
     font-family: 'Helvetica';
     src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
     font-display: fallback;
   }

   @font-face {
     font-family: 'Ubuntu';
     src: url('/fonts/Ubuntu-Regular.ttf') format('truetype');
     font-weight: 500;
     font-display: fallback;
   }

   @font-face {
     font-family: 'Ubuntu';
     src: url('/fonts/Ubuntu-Bold.ttf') format('truetype');
     font-weight: 700;
     font-display: fallback;
   }

   ::view-transition-old(root),
   ::view-transition-new(root) {
       animation: none;
       mix-blend-mode: normal;
   }
   
   * {
       //border-inline: 1px solid red;
       box-sizing: border-box;
   }
   
   body {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     font-family: ${({theme}) => getFontFamily(theme)};
     user-select: none;
   }

   p {
       margin: 0;
       white-space: pre-line;
   }
   
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
       margin: 0;
   }
  
`;
