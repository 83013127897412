import {QNAForm, QNAResponse} from '../../interfaces/api/qna';
import {api} from '../api';
import http from '../http';

export const qna = {
  sendQNA: (body: QNAForm): Promise<QNAResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.qna.ask, body)
        .then(res => resolve(res as QNAResponse))
        .catch(e => reject(e));
    }),
};
