import React from 'react';

const SvgComponent = props => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x={0.707}
      y={15.556}
      width={21}
      height={21}
      rx={4.5}
      transform="rotate(-45 .707 15.556)"
      stroke={'rgb(60,183,103)'}
    />
    <path
      d="m20 13-5.5 5.6-2.5-2.546"
      stroke={'rgb(60,183,103)'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
