import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';
import {ChangePasswordBody} from '../../../interfaces/api/tabs';
import {services} from '../../../api';
import {showGlobalToast} from '../../../connected-components/toast/actions';

/**
 * this function to change password
 *
 * @param body {ChangePasswordBody}
 */
export const changePassword = async (
  body: ChangePasswordBody,
): Promise<boolean> => {
  try {
    startLoading();
    const res = await services.home.changePassword(body);
    if (res?.message) {
      showGlobalToast({
        message: res?.message,
        type: 'success',
      });
    }
    return true;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('CHANGE PASSWORD FAIL: ', error);
    }
    return false;
  } finally {
    finishLoading();
  }
};
