import i18n from './i18n';
import memoize from 'lodash.memoize';
import {saveLanguage} from './reducer';
import {store} from '../redux/store';

export const tr = memoize(
  (key: string, config: Record<string, any> = {}): string => {
    const result = i18n.t(key, config);
    if (typeof result === 'string') {
      return result;
    }
    return JSON.stringify(result);
  },
  (key: string, config: Record<string, any> = {}): string =>
    config ? key + JSON.stringify(config) : key,
);

export default (language: string): void => {
  if (!language) {
    language = 'en';
  }
  i18n.changeLanguage(language);
};

export const setLanguage = (languageCode: 'en' | 'ar') => {
  store.dispatch(saveLanguage(languageCode));
};
