import {createSlice} from '@reduxjs/toolkit';
import {LoadingState} from '../../interfaces/connected-components/loading';

const initialState: LoadingState = {
  isVisible: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startGlobalLoading: state => {
      state.isVisible = true;
    },
    finishGlobalLoading: state => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {startGlobalLoading, finishGlobalLoading} = loadingSlice.actions;

export default loadingSlice.reducer;
