import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {IconsProps} from '../../interfaces/components/icon';
import MyPressable from '../myPressable';
import icons from '../../assets/icons/adapter';
import {isSmallHeight} from '../../utils/responsive';
const Label = styled.span`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.primary};
  margin-left: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Ripple = styled(MyPressable)`
  width: ${isSmallHeight ? 34 : 40}px;
  height: ${isSmallHeight ? 34 : 40}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = ({
  name,
  type = 'SVG',
  label,
  onPress,
  style,
  role,
  loading,
  ...props
}: IconsProps) => {
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsSpinning(true);
      const interval = setInterval(() => {
        setIsSpinning(prev => !prev);
      }, 1500);
      return () => clearInterval(interval);
    } else {
      setIsSpinning(false);
    }
  }, [loading]);

  const icon = useCallback(() => {
    const iconProps = {
      style: {
        ...(!label && !onPress && style),
        ...(loading && isSpinning && {animation: 'spin 1.5s linear infinite'}),
      },
      name,
      ...props,
    };
    switch (type) {
      // case 'AntDesign':
      //   return <AiOutlineAntDesign {...iconProps} />;
      // case 'Octicons':
      //   return <SiOcticons {...iconProps} />;
      // case 'Foundation':
      //   return <SiFoundation {...iconProps} />;
      // case 'MaterialDesign':
      //   return <SiMaterialdesign {...iconProps} />;
      // case 'Ionicons':
      //   return <IoIosIonicons {...iconProps} />;
      // case 'MaterialIcons':
      //   return <MdMaterialIcons {...iconProps} />;
      // case 'SimpleIcons':
      //   return <SiSimpleicons {...iconProps} />;
      // case 'Fontisto':
      //   return <SiFontisto {...iconProps} />;
      // case 'Zalo':
      //   return <SiZalo {...iconProps} />;
      case 'SVG':
        if (!icons[name as keyof typeof icons]) {
          if (process.env.REACT_APP_DEBUG === 'true') {
            console.warn('Icon name: ' + name + " doesn't exist");
          }
          return null;
        }
        const IconComponent = icons[name as keyof typeof icons];
        return (
          <IconComponent
            key={name}
            name={name}
            width={props.width || props.size}
            height={props.height || props.size}
            fill={props.color}
            stroke={props?.stroke}
            style={style}
          />
        );
      default:
        return null;
      // return <FaCheck {...iconProps} />;
    }
  }, [label, onPress, props, role, loading, style, type, isSpinning]);

  if (role === 'button') {
    return (
      <Ripple borderRadius={24} style={style} onClick={onPress}>
        {icon()}
      </Ripple>
    );
  }

  if (onPress) {
    return (
      <button
        style={{...(style || {}), ...{border: 'none', background: 'none'}}}
        onClick={onPress}>
        {icon()}
      </button>
    );
  }
  if (label) {
    return (
      <Row style={style}>
        {icon()}
        <Label>{label}</Label>
      </Row>
    );
  }

  return <>{icon()}</>;
};

export default Icon;
