import * as React from 'react';

const SvgComponent = props => (
  <svg width={31} height={26} viewBox="0 0 31 26" {...props} fill="none">
    <g filter="url(#a)">
      <path stroke="url(#b)" strokeLinecap="round" d="M5 4h21" />
    </g>
    <g filter="url(#c)">
      <path stroke="url(#d)" strokeLinecap="round" d="M5 11h21" />
    </g>
    <g filter="url(#e)">
      <path stroke="url(#f)" strokeLinecap="round" d="M5 18h21" />
    </g>
    <g filter="url(#g)" shapeRendering="crispEdges">
      <circle cx={9} cy={4} r={2} fill="url(#h)" />
      <circle cx={9} cy={4} r={1.5} stroke="url(#i)" />
    </g>
    <g filter="url(#j)" shapeRendering="crispEdges">
      <circle cx={16} cy={11} r={2} fill="url(#k)" />
      <circle cx={16} cy={11} r={1.5} stroke="url(#l)" />
    </g>
    <g filter="url(#m)" shapeRendering="crispEdges">
      <circle cx={22} cy={18} r={2} fill="url(#n)" />
      <circle cx={22} cy={18} r={1.5} stroke="url(#o)" />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={14.792}
        x2={14.792}
        y1={4}
        y2={5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={14.792}
        x2={14.792}
        y1={11}
        y2={12}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={14.792}
        x2={14.792}
        y1={18}
        y2={19}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="h"
        x1={8.865}
        x2={8.865}
        y1={2}
        y2={6}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="i"
        x1={8.865}
        x2={8.865}
        y1={2}
        y2={6}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={15.865}
        x2={15.865}
        y1={9}
        y2={13}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="l"
        x1={15.865}
        x2={15.865}
        y1={9}
        y2={13}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="n"
        x1={21.865}
        x2={21.865}
        y1={16}
        y2={20}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
      <linearGradient
        id="o"
        x1={21.865}
        x2={21.865}
        y1={16}
        y2={20}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
