import styled, {keyframes} from 'styled-components';
import {useGlobalAudioPlayer} from 'react-use-audio-player';
import {useLocation} from 'react-router-dom';
import Paths from '../../config/paths';

const pulseAnimation = keyframes`
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const PulseContainer = styled.div<{isPrimary:boolean}>`
  height: auto;
  width: 100%;
  aspect-ratio: 1;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: -1;
  pointer-events: none;
  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${({theme,isPrimary}) => isPrimary?'#00fff9':theme.text.light};
    border-radius: 50%;
    z-index: -1;
    opacity: 0.3;
  }

  &:before {
    animation: ${pulseAnimation} 1.5s ease-out infinite;
  }

  &:after {
    animation: ${pulseAnimation} 1.5s 0.5s ease-out infinite;
  }
`;

const Pulse = ({children}: any) => {
  const location = useLocation();
  // sound
  const audio = useGlobalAudioPlayer();
  return <PulseContainer isPrimary={audio?.playing&&location.pathname===Paths.content.genioChat}>{children}</PulseContainer>;
};

export default Pulse;
