import {LanguageItemType} from '../interfaces/connected-components/languagePickerModal';
import {languagesData} from '../assets/static/languages';

export const searchAndFilterLanguages = (
  searchName: string = '',
  languageCodes: string[] | null = null,
  tempLanguages: LanguageItemType[] | null = null,
): LanguageItemType[] => {
  const lowerCaseSearchName = searchName.toLowerCase().trim();

  // Use tempLanguages if provided, otherwise fall back to the LANGUAGES array
  const languagesArray = (
    tempLanguages ? tempLanguages : languagesData
  ) as LanguageItemType[];

  // If searchName is empty and languageCodes is null/undefined, return all languages
  if (!lowerCaseSearchName && (!languageCodes || languageCodes.length === 0)) {
    return languagesArray;
  }

  let filteredLanguages = languagesArray;

  // Filter by language codes if provided and tempLanguages is not used
  if (!tempLanguages && languageCodes && languageCodes.length > 0) {
    filteredLanguages = languageCodes
      .map(code => languagesData.find(language => language.code === code))
      .filter(language => language !== undefined) as LanguageItemType[];
  }

  // Further filter by searchName if provided
  if (lowerCaseSearchName) {
    filteredLanguages = filteredLanguages.filter(language =>
      language.name.toLowerCase().includes(lowerCaseSearchName),
    );
  }

  return filteredLanguages;
};
