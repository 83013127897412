import {api} from '../api';
import http from '../http';
import {GetRecommendationsResponse} from '../../interfaces/api/content/recomendations';
import {
  ChangePasswordBody,
  ChangePasswordResponse,
  GetNewsDetailsResponse,
  SearchNewsForm,
} from '../../interfaces/api/tabs';
import {GetHomeTabsResponse} from '../../interfaces/api/content/home';
import {store} from '../../redux/store';

export const home = {
  getHomeTabs: (): Promise<GetHomeTabsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.content.home, undefined, true)
        .then(res => resolve(res as GetHomeTabsResponse))
        .catch(e => reject(e));
    }),
  getRecommendations: (
    page: number,
    pageSize?: number,
  ): Promise<GetRecommendationsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.content.recommendations}?page=${page}&pageSize=${
        pageSize || 20
      }`;
      http
        .get(url)
        .then(res => resolve(res as GetRecommendationsResponse))
        .catch(e => reject(e));
    }),
  getRecommendationsByTab: ({
    tabId,
    page,
    pageSize,
    ...filters
  }: SearchNewsForm): Promise<GetRecommendationsResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};
      if (!tabId || !speciality_id) {
        throw new Error('tab id and speciality id are required');
      }
      let url = `${api.content.recommendationsByTab}`;
      const params = new URLSearchParams();
      params.append('tab', tabId.toString());
      params.append('speciality_id', speciality_id.toString());
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          params.append(key, String(filters[key]));
        }
      });

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', (pageSize || 20).toString());
      }
      url += `?${params.toString()}`;
      http
        .get(url)
        .then(res => resolve(res as GetRecommendationsResponse))
        .catch(e => reject(e));
    }),
  getRecommendationDetails: (id: number): Promise<GetNewsDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.content.recommendationsDetails}/${id}`;
      http
        .get(url)
        .then(res => resolve(res as GetNewsDetailsResponse))
        .catch(e => reject(e));
    }),
  changePassword: (body: ChangePasswordBody): Promise<ChangePasswordResponse> =>
    new Promise((resolve, reject) => {
      http
        .put(api.content.changePassword, body)
        .then(res => resolve(res as ChangePasswordResponse))
        .catch(e => reject(e));
    }),
};
