import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={29}
    viewBox={'0 0 33 29'}
    style={{
      scale: 0.6,
    }}
    {...props}
    stroke="none">
    <g filter="url(#a)">
      <path
        fill="url(#b)"
        fillOpacity={0.85}
        d="m16.5 19-5.63-8.25h11.26L16.5 19Z"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={16.12}
        x2={16.12}
        y1={10.75}
        y2={19}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
      <filter
        id="a"
        width={43.258}
        height={40.25}
        x={-5.129}
        y={-5.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={8} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_265_72"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_265_72"
          result="effect2_dropShadow_265_72"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_265_72"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect3_innerShadow_265_72" />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;
