import React, {useEffect, useRef, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import GenericButton from '../../../../components/button';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux/store';
import GenericInput from '../../../../components/textInput';
import {ProfileScreenItemProps} from '../../../../interfaces/screens/profileScreen';
import {convertToYYYYMMDD, getUserDetailsByIds} from '../../../../utils/helper';
import {tr} from '../../../../translation';
import Image from '../../../../components/Image';
import {Theme} from '../../../../theme/lightTheme';
import placeholderImage from '../../../../assets/images/user.png';
import {motion} from 'framer-motion';

const Container = styled.div<any>`
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({theme, fromDrawer}) =>
    fromDrawer ? `${theme.dimensions.hdp(100)}px` : 'auto'};
`;
const ContentContainer = styled.div`
  overflow-y: auto;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div<{fromDrawer?: boolean}>`
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(16px);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({theme, fromDrawer}) =>
    theme?.isMobile ? '90%' : fromDrawer ? '77%' : '60%'};
  padding: 4%;
  margin: 6% auto;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 26px;

  ${({fromDrawer}) =>
    !fromDrawer &&
    css`
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(16px);
    `};
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const InputWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  pointer-events: none;
  @media (min-width: 768px) {
    width: 48%;
  }
`;
const TopContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 5% 0;
`;
const ItemsContainer = styled.div<any>`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Name = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({theme}: {theme: Theme}) => (theme.isMobile ? 5 : 7)}rem;
  aspect-ratio: 1;
  overflow: hidden;
  border-color: ${({theme}) => theme.homeBackground};
  border-width: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.85;
`;

const ViewProfile: React.FC<ProfileScreenItemProps> = ({
  toggleEdit,
  fromDrawer,
  activeIndex,
}) => {
  const theme = useTheme() as Theme;
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const {currentUser, config} = useSelector(
    (state: RootState) => state.auth,
  ) || {
    currentUser: {},
  };
  const {
    gender: gender_id,
    profession_id,
    country_id,
    language_id,
    speciality_id,
    id,
    email,
    first_name,
    last_name,
    date_of_birth,
  } = currentUser || {};

  const [userDetails, setUserDetails] = useState<any>({
    gender: undefined,
    country: undefined,
    profession: undefined,
    speciality: undefined,
    language: undefined,
  });

  useEffect(() => {
    const details = getUserDetailsByIds({
      config,
      country_id,
      gender_id,
      profession_id,
      language_id,
      speciality_id,
    });
    setUserDetails(details);
  }, [currentUser]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    if (!fromDrawer) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [activeIndex]);

  const {gender, country, profession, speciality} = userDetails;
  const profileList = [
    {label: 'email', value: email},
    {label: 'firstName', value: first_name},
    {label: 'lastName', value: last_name},
    {label: 'gender', value: gender?.name},
    {label: 'country', value: country?.name},
    {label: 'profession', value: profession?.name},
    {label: 'specialty', value: speciality?.name},
    // {label: tr('register.language'), value: language?.name},
    {
      label: 'bod',
      value: convertToYYYYMMDD(date_of_birth || '') ?? '---',
    },
    {label: 'id', value: id},
  ];
  const _renderItem = (item: any) => {
    return (
      <InputWrapper key={item.label}>
        <GenericInput
          label={tr(`register.${item.label}`)}
          labelStyle={{
            fontWeight: 'bold',
          }}
          style={{
            width: '100%',
          }}
          value={
            item.value && item.value != 'null' && item.value != 'undefined'
              ? item.value
              : '---'
          }
        />
      </InputWrapper>
    );
  };

  const _renderScreen = () => {
    return (
      <FormContainer fromDrawer={fromDrawer}>
        <InputContainer>
          <TopContainer>
            <ProfileImageContainer>
              <Image
                source={currentUser?.img}
                placeholderImage={placeholderImage}
              />
            </ProfileImageContainer>
            <Name>{first_name + ' ' + last_name}</Name>
          </TopContainer>
          <ItemsContainer>
            {(profileList || []).map(_renderItem)}
          </ItemsContainer>
        </InputContainer>
        <GenericButton
          outline
          onPress={toggleEdit}
          label={tr('profile.edit')}
          labelStyle={{color: theme.text.light}}
          style={{
            marginBottom: '5%',
            padding: '0 5%',
            borderColor: theme.text.light,
          }}
        />
      </FormContainer>
    );
  };
  if (!fromDrawer) {
    return _renderScreen();
  }
  return (
    <Container fromDrawer={fromDrawer}>
      <ContentContainer ref={scrollableDivRef}>
        {_renderScreen()}
      </ContentContainer>
    </Container>
  );
};
export default ViewProfile;
