import {DrawerItem} from '../../../interfaces/router/drawerItem';
import React from 'react';
import Paths from '../../../config/paths';
import ChangePassword from '../../../screens/content/changePassword';
import FontChanger from '../../../screens/content/fontChanger';
import ChooseYourSpeciality from '../../../screens/content/chooseYourSpeciality';
import ProfilePage from '../../../screens/content/profile';
import Support from '../../../screens/content/support';

const subMenuProfile = () => <ProfilePage fromDrawer />;
const subMenuChangePassword = () => <ChangePassword />;
const subMenuFontChanger = () => <FontChanger />;
const menuChooseYourSpeciality = () => <ChooseYourSpeciality />;
const menuSupport = () => <Support />;

export const drawerRoutes: DrawerItem[] = [
  {
    name: 'home',
    to: Paths.content.home,
    iconName: 'homeDrawer',
  },
  {
    name: 'chooseYourSpeciality',
    to: Paths.content.chooseYourSpeciality,
    compactComponent: menuChooseYourSpeciality,
    iconName: 'stars',
  },
  {
    name: 'privacyPolicy',
    to: Paths.content.privacyPolicy,
    iconName: 'privacy',
  },
  {
    name: 'privacyPolicyForm',
    to: Paths.content.privacyPolicyForm,
    iconName: 'policy',
  },
  {
    name: 'termsConditions',
    to: Paths.content.termsConditions,
    iconName: 'terms',
  },
  {
    name: 'settings',
    to: Paths.content.settings,
    iconName: 'settings',
    subMenuItems: [
      {name: 'profilePage', compactComponent: subMenuProfile},
      {name: 'changePassword', compactComponent: subMenuChangePassword},
      {name: 'darkMode'},
      {name: 'fontSize', compactComponent: subMenuFontChanger},
    ],
  },
  {
    name: 'contactUs',
    to: Paths.content.contactUs,
    compactComponent: menuSupport,
    iconName: 'support',
  },
  {
    name: 'logout',
    iconName: 'logout',
  },
];
