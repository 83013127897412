import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  LanguagePickerModalState,
  OpenLanguagePickerProps,
} from '../../interfaces/connected-components/languagePickerModal';

const initialState: LanguagePickerModalState = {
  isVisible: false,
  currentValue: 'en',
  filteredLanguageCodes: [],
  withSearch: false,
};

export const languagePickerModalSlice = createSlice({
  name: 'languagePickerModal',
  initialState,
  reducers: {
    showLanguagePickerModal: (
      state,
      action: PayloadAction<OpenLanguagePickerProps>,
    ) => {
      state.isVisible = true;
      state.onSelectionChange = action.payload.onSelectionChange;
      state.currentValue = action.payload.currentValue;
      state.filteredLanguageCodes = action.payload.filteredLanguageCodes;
      state.withSearch = action.payload.withSearch;
    },
    hideLanguagePickerModal: state => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {showLanguagePickerModal, hideLanguagePickerModal} =
  languagePickerModalSlice.actions;

export default languagePickerModalSlice.reducer;
