import React from 'react';

const SvgComponent = props => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    {...props}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="url(#a)"
      fillOpacity={0.85}
      d="M23 11.5C23 5.15 17.851 0 11.5 0S0 5.15 0 11.5c0 5.74 4.205 10.498 9.703 11.36v-8.036h-2.92V11.5h2.92V8.966c0-2.882 1.717-4.474 4.344-4.474 1.258 0 2.574.225 2.574.225v2.83h-1.45c-1.429 0-1.874.886-1.874 1.796V11.5h3.19l-.51 3.324h-2.68v8.036C18.795 21.998 23 17.24 23 11.5Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={10.724}
        x2={10.724}
        y1={22.86}
        y2={0}
        gradientUnits="userSpaceOnUse">
        <stop stopColor={props.fill || '#009F9C'} />
        <stop offset={1} stopColor={props.fill || '#007BB2'} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
