import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    viewBox={'0 0 21 20'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#fff'}
      d="M10.257 6.281a3.749 3.749 0 0 0-3.744 3.745 3.75 3.75 0 0 0 3.744 3.745 3.75 3.75 0 0 0 3.745-3.745 3.749 3.749 0 0 0-3.745-3.745Zm0 5.839a2.096 2.096 0 0 1-2.094-2.094c0-1.155.94-2.094 2.094-2.094 1.155 0 2.094.94 2.094 2.094s-.94 2.094-2.094 2.094Z"
    />
    <path
      fill={props.fill || '#fff'}
      d="M18.614 9.376a8.285 8.285 0 0 0-.544-2.391l1.378-1.514-2.705-3.392-1.784 1.008a8.284 8.284 0 0 0-2.208-1.064L12.427 0h-4.34l-.323 2.022c-.786.244-1.526.6-2.209 1.065L3.771 2.078 1.066 5.471l1.379 1.513A8.286 8.286 0 0 0 1.9 9.375L0 10.142l.965 4.23 2.045-.135a8.548 8.548 0 0 0 1.528 1.916l-.586 1.964L7.862 20l1.17-1.682a8.42 8.42 0 0 0 2.451 0L12.653 20l3.909-1.883-.586-1.964a8.55 8.55 0 0 0 1.528-1.916l2.045.135.965-4.23-1.9-.767Zm-2.079 3.143-.244.493a6.806 6.806 0 0 1-1.78 2.23l-.426.349.493 1.65-1.374.661-.982-1.413-.538.116a6.788 6.788 0 0 1-2.853 0l-.538-.116-.983 1.413-1.373-.661.493-1.65-.427-.349a6.801 6.801 0 0 1-1.779-2.23l-.244-.493-1.718.113-.339-1.486 1.599-.644.006-.551c.01-.972.223-1.907.632-2.78l.234-.499L3.234 5.4l.951-1.191 1.501.848.434-.34A6.656 6.656 0 0 1 8.687 3.48l.536-.128.273-1.7h1.524l.272 1.7.536.128c.939.225 1.803.64 2.567 1.238l.434.339 1.501-.848.95 1.191-1.159 1.273.234.5c.41.872.622 1.807.633 2.779l.006.55 1.598.645-.339 1.486-1.717-.113Z"
    />
  </svg>
);
export default SvgComponent;
