import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={14}
    viewBox={'0 0 31 14'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#959595'}
      strokeLinecap="round"
      strokeWidth={3}
      d="M1.7 1.5H29M10.1 12H29"
    />
  </svg>
);
export default SvgComponent;
