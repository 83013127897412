import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {RangeSliderProps} from '../../interfaces/components/rangeSlider';

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const RangeInput = styled.input<{sliderColor: string; thumbColor: string}>`
  outline: none;
  -webkit-appearance: none;
  height: 3px;
  border-radius: 10px;
  width: 100%;
  margin: 10px auto;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin-top: -20px;
    background-color: ${({thumbColor, theme}) =>
      thumbColor || theme.text.light};
    border-radius: 50%;
    cursor: grab;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: ${({thumbColor, theme}) =>
      thumbColor || theme.text.light};
    border-radius: 50%;
    cursor: grab;
  }

  &::-ms-thumb {
    width: 20px;
    height: 20px;
    background-color: ${({thumbColor, theme}) =>
      thumbColor || theme.text.light};
    border-radius: 50%;
    cursor: grab;
  }
`;

const Output = styled.output<{isHovered?: boolean}>`
  position: absolute;
  background-color: ${({theme}) => theme.text.light};
  width: 30px;
  height: 30px;
  text-align: center;
  color: #181818;
  border-radius: 100px;
  display: inline-block;
  font-size: 12px;
  bottom: calc(100% + 10px);
  left: 0;
  vertical-align: middle;
  line-height: 30px;
  opacity: ${({isHovered}) => (isHovered ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid ${({theme}) => theme.text.light};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 90%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
  }
`;

const DataList = styled.datalist`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: auto;
  bottom: 0;
  user-select: none;
  pointer-events: none;
`;

const DataListOption = styled.option`
  border-radius: 100px;
  white-space: nowrap;
  line-height: 40px;
  color: ${({theme}) => theme.text.light};
`;

const RangeSlider: React.FC<RangeSliderProps> = ({
  style,
  min,
  max,
  step,
  value,
  options,
  sliderColor = '#a8a8a8',
  thumbColor = '#f8f8f8',
  onChange,
}: RangeSliderProps): JSX.Element => {
  const [label, setLabel] = useState<string>(options[0].label);
  const outputRef = useRef<HTMLOutputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const outputElement = inputRef.current;

    if (outputElement) {
      outputElement.addEventListener('mouseenter', handleMouseEnter);
      outputElement.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (outputElement) {
        outputElement.removeEventListener('mouseenter', handleMouseEnter);
        outputElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    updateSliderStyles();
    window.addEventListener('resize', updateSliderStyles);
    setLabel(options.find(opt => opt.value === value)?.label || '');

    return () => window.removeEventListener('resize', updateSliderStyles);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    const selectedOption = options.find(opt => opt.value === newValue);
    if (selectedOption && onChange) {
      onChange(selectedOption);
    }
  };

  const updateSliderStyles = () => {
    const el = inputRef.current;
    if (el) {
      const valPercent = (value - min) / (max - min);
      el.style.backgroundImage = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${valPercent}, ${thumbColor}), color-stop(${valPercent}, ${sliderColor}))`;
    }

    const selectedOption = document.querySelector(
      `option[value="${value}"]`,
    ) as HTMLOptionElement;
    if (outputRef.current && selectedOption) {
      outputRef.current.style.left = `${selectedOption.offsetLeft + selectedOption.offsetWidth / 2 - outputRef.current.offsetWidth / 2}px`;
    }
  };

  return (
    <SliderWrapper style={style}>
      <RangeInput
        ref={inputRef}
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        list="ticks2"
        onInput={handleInputChange}
        sliderColor={sliderColor}
        thumbColor={thumbColor}
      />
      <DataList id="ticks2">
        {(options || []).map(opt => (
          <DataListOption key={opt.value} value={opt.value}>
            {opt.label}
          </DataListOption>
        ))}
      </DataList>
      <Output isHovered={isHovered} ref={outputRef}>
        {label || ''}
      </Output>
    </SliderWrapper>
  );
};

export default RangeSlider;
