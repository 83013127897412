import React, {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={552}
    height={392}
    viewBox="0 0 414 294"
    {...props}
    fill="none"
    stroke="none">
    <path
      fill={props?.fill || '#fff'}
      d="M133.9 69.5c-5.6 1.4-11.5 7.3-12.4 12.4-.3 2.1-.8 10.8-1 19.2-.2 8.5-.5 16.3-.7 17.4-.3 1.9.3 2 9.2 2h9.5l-.5-12c-.2-6.6-.4-14.4-.5-17.3v-5.3l74.8-.2 74.7-.2v123l-74.6-.2c-41.1-.1-74.8-.4-75-.6-.2-.2 0-7.4.4-16.2.5-10.3.3-16.3-.3-17.2-.8-1-3.1-1.3-9-1.1l-8 .3.2 17c.3 26.1.9 28 9.4 33.3l3.6 2.2H291l3.8-2.4c5.1-3.2 6.7-5.3 8.6-10.9 1.4-4.2 1.6-12.4 1.6-65.7 0-52.9-.2-61.5-1.6-65.6-2-6.1-7.6-11.2-12.9-11.9-7.4-1.1-152.2-1.1-156.6 0z"
    />
    <path
      fill={props?.fill || '#fff'}
      d="M207.9 103.4c-.2.2-.4 4.2-.3 8.8l.1 8.3h61.7v-17l-30.5-.3c-16.8-.1-30.7 0-31 .2zM155.3 130l.6 9.1-29.7-.1c-16.3 0-30.3-.1-31 0-1 0-1.2 2.1-1 8.7l.3 8.7 26-.2c14.3-.2 27.8-.5 30.1-.8 5.1-.7 5.4 0 4.5 8.8-.7 7-.5 8.8 1.1 8.8.9 0 8.4-5.7 11.9-9.2 1-.9 3-2.5 4.6-3.5 1.5-1 3.8-2.6 5-3.7 2.4-2 5.9-4.2 8.3-5.2.8-.3 2.3-1.6 3.2-2.8 1.7-2.3 1.6-2.3-7-7.9-4.8-3.1-10.1-7-11.9-8.6-1.8-1.6-5.3-4.4-7.8-6.2-2.5-1.8-4.5-3.6-4.5-4.1 0-.4-.7-.8-1.6-.8-1.5 0-1.6 1.1-1.1 9zM208.6 139.2c-.8.5-1.1 3-.9 8.4.1 4.2.5 7.9.7 8.1.2.2 13.9.5 30.5.6 26.2.2 30.1 0 30.1-1.3 0-.8.2-4.6.3-8.3.2-3.8 0-7-.5-7.3-1.2-.8-58.8-1-60.2-.2zM208.3 174.4c-.8 1.2-.8 15.5 0 16.2.7.7 33.7.7 34.4 0 .9-.8.7-15.5-.1-16.3-1.1-1.1-33.7-1-34.3.1z"
    />
  </svg>
);

export default SvgComponent;
