import React from 'react';
import styled, {css} from 'styled-components';
import {ToggleMenuIconProps} from '../../interfaces/components/toggleMenuIcon';

const Container = styled.div<{iconSize: string}>`
  width: ${({iconSize}) => `calc(${iconSize})`};
  height: ${({iconSize}) => `calc(${iconSize} / 1.33)`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const NavIcon = styled.div<{isOpen: boolean; iconSize: string}>`
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  position: relative;

  span {
    display: block;
    position: absolute;
    height: calc(${({iconSize}) => iconSize} / 7);
    border-radius: calc(${({iconSize}) => iconSize} / 7);
    width: 100%;
    aspect-ratio: 1;
    background: linear-gradient(
      45deg,
      ${({theme}) => theme.primary + 'DD'},
      ${({theme}) => theme.secondary + 'DD'}
    );
    opacity: 1;
    left: 0%;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
  }
  span:nth-child(1) {
    top: 0%;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 43%;
  }
  span:nth-child(4) {
    top: 86%;
  }

  ${({isOpen}) =>
    isOpen &&
    css`
      span:nth-child(1),
      span:nth-child(4) {
        top: 43%;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }
    `}
`;

const ToggleMenuIcon = ({
  className = '',
  isOpen = false,
  onClick,
  iconSize = '10rem',
}: ToggleMenuIconProps): JSX.Element => {
  return (
    <Container iconSize={iconSize} className={className}>
      <NavIcon
        iconSize={iconSize}
        className="inner"
        isOpen={isOpen}
        onClick={onClick}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </NavIcon>
    </Container>
  );
};

export default ToggleMenuIcon;
