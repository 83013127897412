import React from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../components/icon';
import GenericButton from '../../../components/button';
import {tr} from '../../../translation';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';

const Container = styled(AnimatedRouterLayout)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({theme}) => theme.dimensions.hdp(100)}px;
  padding: 5%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0.15) 122.02%
  );
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
`;
const NotFoundText = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s4};
  font-weight: bold;
  letter-spacing: 1px;
  padding-top: 4%;
  padding-bottom: 2%;
`;
const IconContainer = styled.div`
  width: 50%;
  aspect-ratio: 1.96;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const NotFound = () => {
  useSetMainLayoutProps({
    hideTopOverlay: true,
    showDrawer: false,
    showBack: true,
  });
  useScrollTop();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Goes back to the previous page
  };
  return (
    <Container>
      <IconContainer>
        <Icon type={'SVG'} name={'notFound'} size={'100%'} />
      </IconContainer>
      <NotFoundText>Page Not Found</NotFoundText>
      <GenericButton
        style={{minWidth: '15%'}}
        label={tr('app.goBack')}
        onPress={goBack}
      />
    </Container>
  );
};

export default NotFound;
