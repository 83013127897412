import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const useScrollTop = () => {
  const location = useLocation();

  const handleScroll = () => {
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  useEffect(() => {
    handleScroll();
  }, [location]);
};

export default useScrollTop;
