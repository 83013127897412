import React from 'react';

const SvgComponent = props => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x={0.707}
      y={15.556}
      width={21}
      height={21}
      rx={4.5}
      transform="rotate(-45 .707 15.556)"
      stroke="#AC2F2F"
    />
    <path
      d="m20 12-8 8M12 12l8 8"
      stroke="#AC2F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
