import React from 'react';
import styled, {useTheme, keyframes} from 'styled-components';
import {GenericButtonProps} from '../../interfaces/components/button';
import {Theme} from '../../theme/lightTheme';
import TextGeneric from '../text';
import Icon from '../icon';
import {isSmallHeight} from '../../utils/responsive';

// Define a keyframe animation for the gradient
const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const Container = styled.button<any>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: ${({radius}) => radius}px;
  background: ${({theme, outline, secondaryRipple}) =>
    outline
      ? 'transparent'
      : secondaryRipple
        ? `linear-gradient(180deg, ${theme.secondary} 0%, ${theme.primary} 100%)`
        : `linear-gradient(180deg, ${theme.primary} 0%, ${theme.secondary} 100%)`};
  background-size: 200% 200%;
  animation: ${gradientAnimation} 4s ease infinite;
  ${({dynamicHeight, isSmall}) =>
    !dynamicHeight && `height: ${isSmall ? 35 : isSmallHeight ? 36 : 43}px;`}
  align-items: center;
  outline: none;
  border: 1px solid
    ${({theme, outline}) => (outline ? theme.button.outline : theme.text.light)};
  ${({disabled, theme}) =>
    disabled
      ? 'opacity:0.7'
      : `    
        cursor: pointer;
    transition: background-color 0.2s,
    transform 0.2s;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  &:active {
    opacity: ${disabled ? 1 : 0.8};
    transform: ${disabled ? undefined : 'scale(0.98)'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: ${disabled ? undefined : '0 0 0 1px ' + theme.text.dark + '77'};
  }
    `};
`;
const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 2rem;
`;

const Title = styled(TextGeneric)`
  padding: 0 2%;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftIconContainer = styled(IconContainer)`
  left: 5%;
`;

const RightIconContainer = styled(IconContainer)`
  right: 5%;
`;

const GenericButton: React.FC<GenericButtonProps> = ({
  className,
  label,
  labelStyle,
  radius = 50,
  disabled,
  onPress,
  outline,
  showRightIcon,
  rightIconType,
  rightIconName,
  rightIconColor,
  rightIconSize,
  rightIconStyle,
  rightIconContainerStyle,
  showLeftIcon,
  leftIconName,
  leftIconType,
  leftIconColor,
  leftIconSize,
  leftIconStyle,
  leftIconContainerStyle,
  style,
  isSmall,
  isLabelGradient,
  secondaryRipple = false,
  backgroundColor,
  dynamicHeight,
  outlineBackgroundColor,
  customLeftComponent,
  isSemiBold,
  ...props
}) => {
  const theme = useTheme() as Theme;

  const onPressHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onPress && onPress();
  };

  return (
    <Container
      className={className}
      radius={radius}
      outline={outline}
      secondaryRipple={secondaryRipple}
      theme={theme}
      dynamicHeight={dynamicHeight}
      isSmall={isSmall}
      onClick={onPressHandler}
      disabled={disabled}
      style={style}
      {...props}>
      <ContentContainer>
        {customLeftComponent && customLeftComponent()}
        {showLeftIcon && !!leftIconType && !!leftIconName && (
          <LeftIconContainer style={leftIconContainerStyle}>
            <Icon
              type={leftIconType}
              name={leftIconName}
              color={
                leftIconColor || (outline ? theme.primary : theme.text.light)
              }
              size={leftIconSize || theme.text.s6}
              style={leftIconStyle}
            />
          </LeftIconContainer>
        )}
        <Title
          withGradient={isLabelGradient}
          bold={!isSemiBold}
          semiBold={isSemiBold}
          style={{
            color: outline
              ? secondaryRipple
                ? theme.secondary
                : theme.button.outline
              : theme.button.text,
            fontSize: theme.text.s8,
            letterSpacing: 1,
            ...labelStyle,
          }}
          theme={theme}>
          {label}
        </Title>
        {showRightIcon && !!rightIconType && !!rightIconName && (
          <RightIconContainer style={rightIconContainerStyle}>
            <Icon
              type={rightIconType}
              name={rightIconName}
              color={
                rightIconColor || (outline ? theme.primary : theme.text.light)
              }
              size={rightIconSize || theme.text.s6}
              style={rightIconStyle}
            />
          </RightIconContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default GenericButton;
