import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 130 106" {...props} fill={'none'} stroke={'none'}>
    <g stroke={props.fill || '#ffffff'} strokeWidth={3}>
      <path d="M84 13h43a2 2 0 0 0 0-4H83.473c.345 1.306.522 2.65.527 4ZM3 13h49c.005-1.35.182-2.694.527-4H3a2 2 0 1 0 0 4Zm0 56a2 2 0 1 0 0 4h10.139a14.476 14.476 0 0 1 0-4H3Zm42 2c-.005.669-.05 1.337-.139 2h33.31c.408-1.37.922-2.708 1.536-4H44.861c.088.663.135 1.331.139 2Zm82-32h-4.139a14.484 14.484 0 0 1 0 4H127a2 2 0 0 0 0-4ZM3 43h88.139a14.476 14.476 0 0 1 0-4H3a2 2 0 1 0 0 4Zm92-2a12 12 0 1 0 12-12 12.012 12.012 0 0 0-12 12Zm20 0a8 8 0 1 1-8-8 8.01 8.01 0 0 1 8 8ZM29 59a12 12 0 1 0 12 12 12.013 12.013 0 0 0-12-12Zm0 20a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8Zm27-66A12 12 0 1 0 68 1a12.013 12.013 0 0 0-12 12Zm20 0a8 8 0 1 1-8-8 8.01 8.01 0 0 1 8 8Zm29 44a24 24 0 1 0 24 24 24.028 24.028 0 0 0-24-24Zm0 44a20 20 0 1 1 20-20 20.025 20.025 0 0 1-20 20Z" />
      <path d="M114.52 71.48a1.999 1.999 0 0 0-2.829 0L105 78.172l-6.691-6.692a2 2 0 0 0-2.83 2.83l6.693 6.69-6.692 6.691a2.001 2.001 0 0 0 2.829 2.83L105 83.827l6.691 6.692a2 2 0 1 0 2.829-2.829l-6.692-6.69 6.692-6.692a2.004 2.004 0 0 0 .434-2.18 2.005 2.005 0 0 0-.434-.649Z" />
    </g>
  </svg>
);
export default SvgComponent;
