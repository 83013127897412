import React, {memo} from 'react';
import styled, {useTheme} from 'styled-components';
import {NavLink, useLocation} from 'react-router-dom';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';
import Pulse from '../pulse';
import {tr} from '../../translation';
import {headerTabsData} from '../../assets/static/headerTabs';
import {isSmallHeight} from '../../utils/responsive';

const iconSize = (theme: any) =>
  theme.isMobile
    ? `${theme.dimensions.wdp(7.7)}px`
    : isSmallHeight
      ? '2rem'
      : '2.5rem';

const Container = styled.div`
  flex-grow: 0;
  padding: ${({theme}) =>
      `calc(${iconSize(theme)} / ${theme.isMobile ? 4 : 7})`}
    0;
  background: linear-gradient(
    180deg,
    ${({theme}) => theme.primary + 'DD'} 0%,
    ${({theme}) => theme.secondary + 'DD'} 100%
  );
  backdrop-filter: blur(3px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  pointer-events: auto;
`;

const TabIconContainer = styled.div<{isMain?: boolean}>`
  &:active {
    scale: ${({isMain}) => (isMain ? 1 : 0.9)};
    opacity: ${({isMain}) => (isMain ? 1 : 0.9)};
  }
  z-index: 99;
`;
const IconWrapper = styled.div<{isMain?: boolean}>`
  background-color: ${({theme, isMain}) =>
    isMain ? theme.text.light : 'transparent'};
  position: ${({isMain}) => (isMain ? 'absolute' : 'relative')};
  padding: ${({isMain, theme}) =>
      isMain ? `calc(${iconSize(theme)} / ${theme.isMobile ? 2 : 2})` : 0}
    ${({isMain, theme}) =>
      isMain
        ? `calc(${iconSize(theme)} / ${theme.isMobile ? 2 : 2})`
        : `calc(${iconSize(theme)} / 1.5)`};
  border-radius: calc(${({theme}) => iconSize(theme)});
  &:active {
    scale: ${({isMain}) => (isMain ? 0.95 : 1)};
    opacity: ${({isMain}) => (isMain ? 0.95 : 1)};
  }
  ${({isMain, theme}) =>
    isMain &&
    (theme.isMobile
      ? `
  bottom: calc(${iconSize(theme)} / 4);
  `
      : `
  bottom: -calc(${iconSize(theme)} / 4);
  `)};
  ${({isMain}) =>
    isMain &&
    `
  left: 50%;
  transform: translateX(-50%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);

  `};
`;
const MainIconContainer = styled.div`
  position: relative;
  width: calc(${({theme}) => iconSize(theme)} * 2.2);
  align-items: center;
`;

const TabBackground = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(${({theme}) => iconSize(theme)} * 1.6);
  aspect-ratio: 1;
  top: 30%;
  left: 50%;
  transform: scale(1) translate(-50%, -50%);
  z-index: -1;
  scale: 1;
  animation: all 5s ease-in-out;
`;
const LabelContainer = styled.div`
  position: absolute;
  bottom: calc(-${({theme}) => iconSize(theme)} / 1.1);
  left: 0;
  width: 100%;
`;
const Label = styled.p`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.text.light};
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
  font-weight: bold;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
  width: 100%;
`;

/**
 * The header component.
 * Renders a header with title, back button, and optional right icon.
 *
 * @returns {JSX.Element} The rendered header component.
 */
const Header = () => {
  /**
   * Represents the current theme.
   * @type {Theme}
   */
  const theme = useTheme() as Theme;
  const {pathname} = useLocation();

  const _renderTabContent = item => {
    const isSelected = pathname === item.pathname;
    return (
      <NavLink to={item?.pathname}>
        <IconWrapper key={item.label} isMain={item.isMainIcon}>
          <TabIconContainer isMain={item.isMainIcon}>
            {!item.isMainIcon && (
              <TabBackground>
                <Icon
                  stroke={isSelected ? theme.text.light + 'BB' : undefined}
                  name={'tabBackground'}
                />
              </TabBackground>
            )}
            <Icon name={item.iconName} size={iconSize(theme)} />
          </TabIconContainer>
          {item.isMainIcon ? (
            <Pulse />
          ) : !theme.isMobile ? (
            <LabelContainer>
              <Label>{tr(`headerTabs.${item.label}`)}</Label>
            </LabelContainer>
          ) : null}
        </IconWrapper>
      </NavLink>
    );
  };

  const _renderHeaderTab = (item: any) => {
    if (item.isMainIcon) {
      return (
        <MainIconContainer key={item.label}>
          {_renderTabContent(item)}
        </MainIconContainer>
      );
    }
    return _renderTabContent(item);
  };

  return <Container>{headerTabsData.map(_renderHeaderTab)}</Container>;
};

export default memo(Header);
