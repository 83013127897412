import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ModalMessage,
  ModalState,
} from '../../interfaces/connected-components/globalModal';

const initialState: ModalState = {
  isVisible: false,
  message: '',
  title: '',
  type: 'info',
  onConfirm: null,
  onReject: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalMessage>) => {
      state.title = action.payload.title;
      state.message = action.payload.message || '';
      state.isVisible = true;
      state.type = action.payload.type;
      state.onConfirm = action.payload.onConfirm;
      state.onReject = action.payload.onReject;
      state.customTopComponent = action.payload.customTopComponent;
      state.confirmText = action.payload.confirmText;
      state.rejectText = action.payload.rejectText;
    },
    hideModal: state => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {showModal, hideModal} = modalSlice.actions;

export default modalSlice.reducer;
