import authReducer from '../screens/auth/login/reducer';
import languageReducer from '../translation/reducer';
import loadingReducer from '../connected-components/loading/reducer';
import toastReducer from '../connected-components/toast/reducer';
import filtersModalReducer from '../connected-components/filtersModal/reducer';
import globalModalReducer from '../connected-components/globalModal/reducer';
import languagePickerModalReducer from '../connected-components/languagePickerModal/reducer';
import settingsReducer from '../screens/content/settings/reducer';

export default {
  auth: authReducer,
  lang: languageReducer,
  loading: loadingReducer,
  toast: toastReducer,
  filtersModal: filtersModalReducer,
  globalModal: globalModalReducer,
  settings: settingsReducer,
  languagePickerModal: languagePickerModalReducer,
};
