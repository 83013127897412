import React, {type CSSProperties} from 'react';
import styled, {useTheme} from 'styled-components';
import Icon from '../icon';
import {tr} from '../../translation';
import {Theme} from '../../theme/lightTheme';
import GenericButton from '../button';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 5rem;
`;

const Description = styled.p`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.light};
  max-width: 20rem;
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
`;

const ErrorView = ({
  onRefresh,
  refreshText,
  style,
}: {
  onRefresh: () => void;
  refreshText?: string;
  style?: CSSProperties;
}) => {
  const theme = useTheme() as Theme;
  const RefreshHandler = () => {
    onRefresh && onRefresh();
  };

  return (
    <Container style={style}>
      <Icon
        type={'SVG'}
        name={'error'}
        size={'8rem'}
        color={theme.text.light}
      />
      <Description> {tr('app.serverErrorDescription')} </Description>
      <GenericButton
        isSmall
        label={refreshText || tr('app.tryAgain')}
        onPress={RefreshHandler}
      />
    </Container>
  );
};

export default ErrorView;
