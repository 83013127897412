import * as React from 'react';

const SvgComponent = props => (
  <svg
    width={51}
    height={35}
    viewBox="0 0 51 35"
    {...props}
    fill="none"
    stroke="none">
    <g filter="url(#a)">
      <rect
        width={43}
        height={27}
        x={4}
        y={2}
        fill="url(#b)"
        fillOpacity={0.85}
        rx={13.5}
      />
    </g>
    <g filter="url(#c)">
      <path stroke={props.fill || '#fff'} strokeLinecap="round" d="M15 9h21" />
    </g>
    <g filter="url(#d)">
      <path stroke={props.fill || '#fff'} strokeLinecap="round" d="M15 16h21" />
    </g>
    <g filter="url(#e)">
      <path stroke={props.fill || '#fff'} strokeLinecap="round" d="M15 23h21" />
    </g>
    <g filter="url(#f)" shapeRendering="crispEdges">
      <circle cx={19} cy={9} r={2} fill="url(#g)" fillOpacity={0.85} />
      <circle cx={19} cy={9} r={1.5} stroke={props.fill || '#fff'} />
    </g>
    <g filter="url(#h)" shapeRendering="crispEdges">
      <circle cx={26} cy={16} r={2} fill="url(#i)" fillOpacity={0.85} />
      <circle cx={26} cy={16} r={1.5} stroke={props.fill || '#fff'} />
    </g>
    <g filter="url(#j)" shapeRendering="crispEdges">
      <circle cx={32} cy={23} r={2} fill="url(#k)" fillOpacity={0.85} />
      <circle cx={32} cy={23} r={1.5} stroke={props.fill || '#fff'} />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={24.05}
        x2={24.05}
        y1={2}
        y2={29}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={18.865}
        x2={18.865}
        y1={7}
        y2={11}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={25.865}
        x2={25.865}
        y1={14}
        y2={18}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={31.865}
        x2={31.865}
        y1={21}
        y2={25}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
