import {store} from '../../redux/store';
import {OpenLanguagePickerProps} from '../../interfaces/connected-components/languagePickerModal';
import {hideLanguagePickerModal, showLanguagePickerModal} from './reducer';

/**
 * This function opens the language picker modal
 */
export const openLanguagePickerModal = ({
  onSelectionChange,
  currentValue = 'en',
  filteredLanguageCodes,
  withSearch = true,
}: OpenLanguagePickerProps) => {
  store.dispatch(
    showLanguagePickerModal({
      onSelectionChange,
      currentValue,
      filteredLanguageCodes,
      withSearch,
    }),
  );
};

/**
 * This function closes the language picker modal
 */
export const closeLanguagePickerModal = () => {
  store.dispatch(hideLanguagePickerModal());
};
