import {LanguageItemType} from '../../interfaces/connected-components/languagePickerModal';

export const languagesData: LanguageItemType[] = [
  {code: 'ab', name: 'Abkhazian', countryCode: 'GE'},
  {code: 'aa', name: 'Afar', countryCode: 'DJ'},
  {code: 'af', name: 'Afrikaans', countryCode: 'ZA'},
  {code: 'ak', name: 'Akan', countryCode: 'GH'},
  {code: 'sq', name: 'Albanian', countryCode: 'AL'},
  {code: 'am', name: 'Amharic', countryCode: 'ET'},
  {code: 'ar', name: 'Arabic', countryCode: 'SA'},
  {code: 'an', name: 'Aragonese', countryCode: 'ES'},
  {code: 'hy', name: 'Armenian', countryCode: 'AM'},
  {code: 'as', name: 'Assamese', countryCode: 'IN'},
  {code: 'av', name: 'Avaric', countryCode: 'RU'},
  {code: 'ay', name: 'Aymara', countryCode: 'BO'},
  {code: 'az', name: 'Azerbaijani', countryCode: 'AZ'},
  {code: 'bm', name: 'Bambara', countryCode: 'ML'},
  {code: 'ba', name: 'Bashkir', countryCode: 'RU'},
  {code: 'eu', name: 'Basque', countryCode: 'ES'},
  {code: 'be', name: 'Belarusian', countryCode: 'BY'},
  {code: 'bn', name: 'Bengali', countryCode: 'BD'},
  {code: 'bh', name: 'Bihari languages', countryCode: 'IN'},
  {code: 'bi', name: 'Bislama', countryCode: 'VU'},
  {code: 'bs', name: 'Bosnian', countryCode: 'BA'},
  {code: 'br', name: 'Breton', countryCode: 'FR'},
  {code: 'bg', name: 'Bulgarian', countryCode: 'BG'},
  {code: 'my', name: 'Burmese', countryCode: 'MM'},
  {code: 'ca', name: 'Catalan, Valencian', countryCode: 'ES'},
  {code: 'km', name: 'Central Khmer', countryCode: 'KH'},
  {code: 'ch', name: 'Chamorro', countryCode: 'GU'},
  {code: 'ce', name: 'Chechen', countryCode: 'RU'},
  {code: 'ny', name: 'Chichewa, Chewa, Nyanja', countryCode: 'MW'},
  {code: 'zh', name: 'Chinese', countryCode: 'CN'},
  {
    code: 'cu',
    name: 'Church Slavonic',
    countryCode: 'BG',
  },
  {code: 'cv', name: 'Chuvash', countryCode: 'RU'},
  {code: 'kw', name: 'Cornish', countryCode: 'GB'},
  {code: 'co', name: 'Corsican', countryCode: 'FR'},
  {code: 'cr', name: 'Cree', countryCode: 'CA'},
  {code: 'hr', name: 'Croatian', countryCode: 'HR'},
  {code: 'cs', name: 'Czech', countryCode: 'CZ'},
  {code: 'da', name: 'Danish', countryCode: 'DK'},
  {code: 'dv', name: 'Divehi, Dhivehi, Maldivian', countryCode: 'MV'},
  {code: 'nl', name: 'Dutch, Flemish', countryCode: 'NL'},
  {code: 'dz', name: 'Dzongkha', countryCode: 'BT'},
  {code: 'en', name: 'English', countryCode: 'GB'},
  {code: 'eo', name: 'Esperanto', countryCode: 'XX'},
  {code: 'et', name: 'Estonian', countryCode: 'EE'},
  {code: 'ee', name: 'Ewe', countryCode: 'GH'},
  {code: 'fo', name: 'Faroese', countryCode: 'FO'},
  {code: 'fj', name: 'Fijian', countryCode: 'FJ'},
  {code: 'fi', name: 'Finnish', countryCode: 'FI'},
  {code: 'fr', name: 'French', countryCode: 'FR'},
  {code: 'ff', name: 'Fulah', countryCode: 'SN'},
  {code: 'gd', name: 'Gaelic, Scottish Gaelic', countryCode: 'GB'},
  {code: 'gl', name: 'Galician', countryCode: 'ES'},
  {code: 'lg', name: 'Ganda', countryCode: 'UG'},
  {code: 'ka', name: 'Georgian', countryCode: 'GE'},
  {code: 'de', name: 'German', countryCode: 'DE'},
  {code: 'ki', name: 'Gikuyu, Kikuyu', countryCode: 'KE'},
  {code: 'el', name: 'Greek (Modern)', countryCode: 'GR'},
  {code: 'kl', name: 'Greenlandic, Kalaallisut', countryCode: 'GL'},
  {code: 'gn', name: 'Guarani', countryCode: 'PY'},
  {code: 'gu', name: 'Gujarati', countryCode: 'IN'},
  {code: 'ht', name: 'Haitian, Haitian Creole', countryCode: 'HT'},
  {code: 'ha', name: 'Hausa', countryCode: 'NG'},
  {code: 'hz', name: 'Herero', countryCode: 'NA'},
  {code: 'hi', name: 'Hindi', countryCode: 'IN'},
  {code: 'ho', name: 'Hiri Motu', countryCode: 'PG'},
  {code: 'hu', name: 'Hungarian', countryCode: 'HU'},
  {code: 'is', name: 'Icelandic', countryCode: 'IS'},
  {code: 'io', name: 'Ido', countryCode: 'XX'},
  {code: 'ig', name: 'Igbo', countryCode: 'NG'},
  {code: 'id', name: 'Indonesian', countryCode: 'ID'},
  {
    code: 'ia',
    name: 'Interlingua (International Auxiliary Language Association)',
    countryCode: 'XX',
  },
  {code: 'ie', name: 'Interlingue', countryCode: 'XX'},
  {code: 'iu', name: 'Inuktitut', countryCode: 'CA'},
  {code: 'ik', name: 'Inupiaq', countryCode: 'US'},
  {code: 'ga', name: 'Irish', countryCode: 'IE'},
  {code: 'it', name: 'Italian', countryCode: 'IT'},
  {code: 'ja', name: 'Japanese', countryCode: 'JP'},
  {code: 'jv', name: 'Javanese', countryCode: 'ID'},
  {code: 'kn', name: 'Kannada', countryCode: 'IN'},
  {code: 'kr', name: 'Kanuri', countryCode: 'NG'},
  {code: 'ks', name: 'Kashmiri', countryCode: 'IN'},
  {code: 'kk', name: 'Kazakh', countryCode: 'KZ'},
  {code: 'rw', name: 'Kinyarwanda', countryCode: 'RW'},
  {code: 'kv', name: 'Komi', countryCode: 'RU'},
  {code: 'kg', name: 'Kongo', countryCode: 'CG'},
  {code: 'ko', name: 'Korean', countryCode: 'KR'},
  {code: 'kj', name: 'Kwanyama, Kuanyama', countryCode: 'NA'},
  {code: 'ku', name: 'Kurdish', countryCode: 'IQ'},
  {code: 'ky', name: 'Kyrgyz', countryCode: 'KG'},
  {code: 'lo', name: 'Lao', countryCode: 'LA'},
  {code: 'la', name: 'Latin', countryCode: 'VA'},
  {code: 'lv', name: 'Latvian', countryCode: 'LV'},
  {code: 'lb', name: 'Letzeburgesch, Luxembourgish', countryCode: 'LU'},
  {code: 'li', name: 'Limburgish, Limburgan, Limburger', countryCode: 'NL'},
  {code: 'ln', name: 'Lingala', countryCode: 'CG'},
  {code: 'lt', name: 'Lithuanian', countryCode: 'LT'},
  {code: 'lu', name: 'Luba-Katanga', countryCode: 'CD'},
  {code: 'mk', name: 'Macedonian', countryCode: 'MK'},
  {code: 'mg', name: 'Malagasy', countryCode: 'MG'},
  {code: 'ms', name: 'Malay', countryCode: 'MY'},
  {code: 'ml', name: 'Malayalam', countryCode: 'IN'},
  {code: 'mt', name: 'Maltese', countryCode: 'MT'},
  {code: 'gv', name: 'Manx', countryCode: 'IM'},
  {code: 'mi', name: 'Maori', countryCode: 'NZ'},
  {code: 'mr', name: 'Marathi', countryCode: 'IN'},
  {code: 'mh', name: 'Marshallese', countryCode: 'MH'},
  {code: 'ro', name: 'Moldovan, Moldavian, Romanian', countryCode: 'RO'},
  {code: 'mn', name: 'Mongolian', countryCode: 'MN'},
  {code: 'na', name: 'Nauru', countryCode: 'NR'},
  {code: 'nv', name: 'Navajo, Navaho', countryCode: 'US'},
  {code: 'nd', name: 'Northern Ndebele', countryCode: 'ZW'},
  {code: 'ng', name: 'Ndonga', countryCode: 'NA'},
  {code: 'ne', name: 'Nepali', countryCode: 'NP'},
  {code: 'se', name: 'Northern Sami', countryCode: 'NO'},
  {code: 'no', name: 'Norwegian', countryCode: 'NO'},
  {code: 'nb', name: 'Norwegian Bokmål', countryCode: 'NO'},
  {code: 'nn', name: 'Norwegian Nynorsk', countryCode: 'NO'},
  {code: 'ii', name: 'Nuosu, Sichuan Yi', countryCode: 'CN'},
  {code: 'oc', name: 'Occitan (post 1500)', countryCode: 'FR'},
  {code: 'oj', name: 'Ojibwa', countryCode: 'CA'},
  {code: 'or', name: 'Oriya', countryCode: 'IN'},
  {code: 'om', name: 'Oromo', countryCode: 'ET'},
  {code: 'os', name: 'Ossetian, Ossetic', countryCode: 'GE'},
  {code: 'pi', name: 'Pali', countryCode: 'IN'},
  {code: 'pa', name: 'Panjabi, Punjabi', countryCode: 'IN'},
  {code: 'ps', name: 'Pashto, Pushto', countryCode: 'AF'},
  {code: 'pl', name: 'Polish', countryCode: 'PL'},
  {code: 'pt', name: 'Portuguese', countryCode: 'PT'},
  {code: 'qu', name: 'Quechua', countryCode: 'PE'},
  {code: 'rm', name: 'Romansh', countryCode: 'CH'},
  {code: 'rn', name: 'Rundi', countryCode: 'BI'},
  {code: 'ru', name: 'Russian', countryCode: 'RU'},
  {code: 'sm', name: 'Samoan', countryCode: 'WS'},
  {code: 'sg', name: 'Sango', countryCode: 'CF'},
  {code: 'sa', name: 'Sanskrit', countryCode: 'IN'},
  {code: 'sc', name: 'Sardinian', countryCode: 'IT'},
  {code: 'sr', name: 'Serbian', countryCode: 'RS'},
  {code: 'sn', name: 'Shona', countryCode: 'ZW'},
  {code: 'sd', name: 'Sindhi', countryCode: 'PK'},
  {code: 'si', name: 'Sinhala, Sinhalese', countryCode: 'LK'},
  {code: 'sk', name: 'Slovak', countryCode: 'SK'},
  {code: 'sl', name: 'Slovenian', countryCode: 'SI'},
  {code: 'so', name: 'Somali', countryCode: 'SO'},
  {code: 'st', name: 'Sotho, Southern', countryCode: 'ZA'},
  {code: 'nr', name: 'South Ndebele', countryCode: 'ZA'},
  {code: 'es', name: 'Spanish, Castilian', countryCode: 'ES'},
  {code: 'su', name: 'Sundanese', countryCode: 'ID'},
  {code: 'sw', name: 'Swahili', countryCode: 'KE'},
  {code: 'ss', name: 'Swati', countryCode: 'SZ'},
  {code: 'sv', name: 'Swedish', countryCode: 'SE'},
  {code: 'tl', name: 'Tagalog', countryCode: 'PH'},
  {code: 'ty', name: 'Tahitian', countryCode: 'PF'},
  {code: 'tg', name: 'Tajik', countryCode: 'TJ'},
  {code: 'ta', name: 'Tamil', countryCode: 'IN'},
  {code: 'tt', name: 'Tatar', countryCode: 'RU'},
  {code: 'te', name: 'Telugu', countryCode: 'IN'},
  {code: 'th', name: 'Thai', countryCode: 'TH'},
  {code: 'bo', name: 'Tibetan', countryCode: 'CN'},
  {code: 'ti', name: 'Tigrinya', countryCode: 'ER'},
  {code: 'to', name: 'Tonga (Tonga Islands)', countryCode: 'TO'},
  {code: 'ts', name: 'Tsonga', countryCode: 'ZA'},
  {code: 'tn', name: 'Tswana', countryCode: 'BW'},
  {code: 'tr', name: 'Turkish', countryCode: 'TR'},
  {code: 'tk', name: 'Turkmen', countryCode: 'TM'},
  {code: 'tw', name: 'Twi', countryCode: 'GH'},
  {code: 'ug', name: 'Uighur, Uyghur', countryCode: 'CN'},
  {code: 'uk', name: 'Ukrainian', countryCode: 'UA'},
  {code: 'ur', name: 'Urdu', countryCode: 'PK'},
  {code: 'uz', name: 'Uzbek', countryCode: 'UZ'},
  {code: 've', name: 'Venda', countryCode: 'ZA'},
  {code: 'vi', name: 'Vietnamese', countryCode: 'VN'},
  {code: 'vo', name: 'Volapük', countryCode: 'DE'},
  {code: 'wa', name: 'Walloon', countryCode: 'BE'},
  {code: 'cy', name: 'Welsh', countryCode: 'GB'},
  {code: 'fy', name: 'Western Frisian', countryCode: 'NL'},
  {code: 'wo', name: 'Wolof', countryCode: 'SN'},
  {code: 'xh', name: 'Xhosa', countryCode: 'ZA'},
  {code: 'yi', name: 'Yiddish', countryCode: 'DE'},
  {code: 'yo', name: 'Yoruba', countryCode: 'NG'},
  {code: 'za', name: 'Zhuang, Chuang', countryCode: 'CN'},
  {code: 'zu', name: 'Zulu', countryCode: 'ZA'},
];
