import {
  GetTabNewsData,
  GetTabNewsForm,
  NewsItemDetailsType,
} from '../../../interfaces/api/tabs';
import {services} from '../../../api';

/**
 * this function to get news List
 *
 * @return {any} result of news List
 */
export const getTabNews = async (
  body: GetTabNewsForm,
): Promise<GetTabNewsData | null> => {
  try {
    const res = await services.news.getTabNews(body);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Tab News FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to get News Details list
 *
 * @return {NewsItemDetailsType | null} news details
 */
export const getNewsDetails = async (
  id: number,
): Promise<NewsItemDetailsType | null> => {
  try {
    const res = await services.news.getNewsDetails(id);
    if (Array.isArray(res?.data)) {
      return res?.data.length > 0 ? res?.data[0] : null;
    }
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get News Details FAIL: ', error);
    }
    return null;
  }
};
