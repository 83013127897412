import React, {useCallback, useEffect, useState} from 'react';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {Theme} from '../../../theme/lightTheme';
import {GetTabNewsData, NewsItemType} from '../../../interfaces/api/tabs';
import {RootState} from '../../../redux/store';
import {useSelector} from 'react-redux';
import Paths from '../../../config/paths';
import NewsCard from '../../../components/newsCard';
import {SpecialityType, TabIds} from '../../../utils/enums';
import {RecommendationsData} from '../../../interfaces/api/content/recomendations';
import {getRecommendations, getRecommendationsByTab} from '../home/actions';
import {getTabNews} from '../tabNews/actions';
import {searchNews} from '../search/actions';
import {debounce} from 'lodash';
import Icon from '../../../components/icon';
import ErrorView from '../../../components/errorView';
import {tr} from '../../../translation';
import NewsSkeleton from '../../../components/newsSkeleton';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';

export const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const zoomOut = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
`;

const Container = styled(AnimatedRouterLayout)<{flex: boolean}>`
  ${({flex}) =>
    flex &&
    `
     display: flex;
     margin-bottom: auto;
    `};

  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 3% 15% 5% 15%;
  @media (max-width: 550px) {
    padding: 5%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const BlurContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0.15) 122.02%
  );
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 26px;
  padding: 1rem;
`;
export const EmptyText = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  font-weight: 400;
  color: ${({theme}) => theme.text.light};
  width: 80%;
  padding-top: 1rem;
  text-align: center;
`;
const NewsListItem = styled.div`
  margin-bottom: 1rem;
  width: 48%;
  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const AnimatedDiv = styled.div<{isZoomedIn?: boolean}>`
  animation: ${zoomOut} 0.3s ease-in-out forwards;
  transform-origin: center;
  display: none;
  ${({isZoomedIn}) =>
    isZoomedIn &&
    css`
      animation: ${zoomIn} 0.3s ease-in-out forwards;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      align-self: center;
    `};
`;

const News = () => {
  const navigation = useNavigate();
  const {
    filtersResult,
    filters,
    tabId,
    search,
    isRecommendation,
    isRecommendationByTab,
    isHomeRecommendation,
    staticRecommendation,
  } = useLocation().state || {};
  const onSearchPress = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    if (Object.keys(filtersResult || {}).length === 0) {
      queryParams.delete('filters');
    } else {
      queryParams.set('filters', JSON.stringify(filtersResult));
    }
    navigation(
      {pathname: Paths.content.search, search: queryParams.toString()},
      {
        state: {tabId, filters, filtersResult},
      },
    );
  }, [filters, filtersResult, location, tabId, navigation]);

  useSetMainLayoutProps({
    showHeader: true,
    showBack: true,
    showSearch:
      !isRecommendation &&
      !isRecommendationByTab &&
      !isHomeRecommendation &&
      !staticRecommendation,
    onSearchPress,
  });
  const theme = useTheme() as Theme;
  const {currentUser} = useSelector((state: RootState) => state.auth);
  const [data, setData] = useState<NewsItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);

  const loadMore = async (refresh = false) => {
    if (staticRecommendation) {
      const specialtyId = currentUser?.speciality_id;
      const specialtyMapping = {
        [SpecialityType.Cardiology]:
          staticRecommendation[SpecialityType.Cardiology],
        [SpecialityType.Respiratory]:
          staticRecommendation[SpecialityType.Respiratory],
        [SpecialityType.Endocrinology]:
          staticRecommendation[SpecialityType.Endocrinology],
        [SpecialityType.Dermatology]:
          staticRecommendation[SpecialityType.Dermatology],
      };
      const specialtyData = specialtyMapping[+(specialtyId || '')] || [];
      setData(specialtyData);
      return;
    }
    if (isLoading) {
      return;
    }
    setIsError(false);
    if (!isLastPage || refresh) {
      if (refresh) {
        setData([]);
      }
      setIsLoading(true);
      let result: GetTabNewsData | RecommendationsData | null = null;
      if (isRecommendationByTab) {
        result = await getRecommendationsByTab({
          tabId,
          page: refresh ? 1 : page,
          ...filtersResult,
        });
      } else if (isHomeRecommendation) {
        result = await getRecommendations(refresh ? 1 : page);
      } else if (search && tabId) {
        result = await searchNews({
          keywords: search,
          tabId,
          page: refresh ? 1 : page,
          ...filtersResult,
        });
      } else if (tabId) {
        result = await getTabNews({
          tabId,
          page: refresh ? 1 : page,
          ...filtersResult,
        });
      }
      if (!result) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
      setData(prev => {
        if (refresh) {
          return [...result?.news];
        } else {
          return [...prev, ...result?.news].filter((value, index, self) => {
            return (
              self.findIndex(obj =>
                ['id'].every(key => obj[key] === value[key]),
              ) === index
            );
          });
        }
      });

      setPage(prev => (refresh ? 2 : prev + 1));
      const totalPageCount = Math.ceil(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        parseFloat(result?.totalCount || result?.total || '2') / 20,
      );
      setIsLastPage((refresh ? 1 : page) >= totalPageCount);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMore(true);
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const threshold =
        document.documentElement.offsetHeight - window.innerHeight / 2;

      if (scrollPosition >= threshold && !isLoading && !isLastPage) {
        loadMore();
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending debounce calls
    };
  }, [isLoading, isError, isLastPage, page]);

  const _renderItem = (item: NewsItemType) => {
    return (
      <NewsListItem key={item?.id}>
        <NewsCard
          title={item?.title || ''}
          description={item?.description || ''}
          image={item?.img}
          style={{
            opacity: !!staticRecommendation && !item?.pdf_link ? 0.7 : 1,
            pointerEvents:
              !!staticRecommendation && !item?.pdf_link ? 'none' : 'auto',
          }}
          onPress={() => {
            if (staticRecommendation) {
              if (item?.pdf_link) {
                navigation(Paths.content.pdfViewer, {
                  state: {...item},
                });
              }
              return;
            }
            if (+tabId === TabIds.Drugs) {
              navigation(Paths.content.drugs, {
                state: {id: item?.id, title: item?.title || item?.name || ''},
              });
              return;
            }
            navigation(Paths.content.article, {
              state: {
                item,
                isRecommendation: isRecommendation,
                isRecommendationByTab: isRecommendationByTab,
              },
            });
          }}
        />
      </NewsListItem>
    );
  };

  return (
    <Container flex={!(!isLoading && !isError && (data || []).length === 0)}>
      {!isError && data?.length > 0 && (data || []).map(_renderItem)}
      {isLoading && !isError && (data || []).length === 0 && <NewsSkeleton />}
      <AnimatedDiv
        isZoomedIn={!isLoading && !isError && (data || []).length === 0}>
        <BlurContainer style={{minWidth: '40%', aspectRatio: 1}}>
          <Icon
            name={'empty'}
            type={'SVG'}
            color={theme.text.light}
            size={theme.text.s2}
          />
          <EmptyText>{tr('app.empty')}</EmptyText>
        </BlurContainer>
      </AnimatedDiv>
      <AnimatedDiv isZoomedIn={isError}>
        <BlurContainer style={{minWidth: '40%', aspectRatio: 1}}>
          <ErrorView onRefresh={() => loadMore(true)} />
        </BlurContainer>
      </AnimatedDiv>
      <AnimatedDiv
        style={{display: data?.length > 0 ? 'flex' : 'none'}}
        isZoomedIn={isLoading && !isError && !isLastPage && data?.length > 0}>
        <BlurContainer>
          <Icon
            name={'loader'}
            type={'SVG'}
            color={theme.text.light}
            size={theme.text.s3}
          />
        </BlurContainer>
      </AnimatedDiv>
    </Container>
  );
};
export default News;
