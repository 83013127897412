import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import NotFound from '../../screens/content/notFound';

/**
 * An array of shared routes for a navigation stack.
 * @type {StackItem[]}
 */
const sharedRoutes: StackItem[] = [
  {
    path: paths.shared.notFound,
    component: NotFound,
  },
];

export default sharedRoutes;
