import {store} from '../../../redux/store';
import {login, resetTabsVoice, setConfig} from './reducer';
import {setLocalData} from '../../../utils/storage';
import {services} from '../../../api';
import {LoginDataType, LoginForm} from '../../../interfaces/api/auth/login';
import {keys} from '../../../api/keys';
import {CurrentUserConfig} from '../../../interfaces/reducers/auth';
import {RegisterForm} from '../../../interfaces/api/auth/register';
import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {tr} from '../../../translation';

/**
 * this function to log in
 */
export const logIn = async (
  body: LoginForm,
  hideMessage?: boolean,
): Promise<{success: boolean; message?: string}> => {
  try {
    startLoading();
    const res = await services.auth.login(body);
    await startSession({
      ...(res?.data || {}),
      password: body?.password,
    });
    if (!hideMessage) {
      showGlobalToast({
        message: tr('login.loginSuccessMessage') + res?.data?.first_name,
        type: 'success',
      });
    }
    return {success: true};
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('LOGIN FAIL: ', error);
    }

    const message = error.response?.data?.error?.message
      ? error.response?.data?.error?.message
      : tr('login.loginFailMessage');
    return {success: false, message};
  } finally {
    finishLoading();
  }
};

/**
 * this function to register
 */
export const register = async (body: RegisterForm): Promise<boolean> => {
  try {
    startLoading();
    const res = await services.auth.register(body);
    await startSession(res?.data);
    showGlobalToast({
      message: tr('login.registerSuccessMessage') + res?.data?.first_name,
      type: 'success',
    });
    return true;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('REGISTER FAIL: ', error);
    }
    return false;
  } finally {
    finishLoading();
  }
};
export const startSession = async (user: LoginDataType) => {
  try {
    const currentUser = {...user};
    await setLocalData(keys.CURRENT_USER, currentUser);
    store.dispatch(login(currentUser));
    return true;
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('LOGIN FAIL: ', error);
    }
    return false;
  }
};

/**
 * clear user session.
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if logout is successful, otherwise `false`.
 */
export const endSession = async () => {
  try {
    startLoading();
    store.dispatch(resetTabsVoice());
    const {currentUser, config: userConfig} = store.getState().auth || {};
    if (currentUser?.token)
      showGlobalToast({
        message: tr('login.logoutSuccessfully'),
        type: 'success',
      });
    await startSession({
      ...currentUser,
      ...userConfig,
      token: null,
      dashboardToken: null,
      is_first_login: false,
    });
    return true;
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('LOGOUT FAIL: ', error);
    }
    return false;
  } finally {
    finishLoading();
  }
};

/**
 * Updates the current configuration asynchronously.
 *
 * @returns A Promise that resolves with a boolean indicating whether the configuration update was successful.
 */
export const updateConfig = async (): Promise<boolean> => {
  try {
    const res = await services.auth.getConfig();
    const {font} = store.getState().auth.config || {};
    if (res?.data) {
      const tempConfig: CurrentUserConfig = {...(res?.data || {}), font};
      store.dispatch(setConfig(tempConfig));
    }
    return true;
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('UPDATE CONFIG FAIL: ', error);
    }
    return false;
  }
};
