import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {RootState} from '../../redux/store';
import {closeGlobalToast} from './actions';
import {ToastProps} from '../../interfaces/connected-components/toast';

/**
 * GlobalToast component that displays a toast message.
 * @param {Object} props - Component props.
 * @param {Object} props.toast - The toast object containing the message and visibility.
 * @returns {React.Component} The GlobalToast component.
 */
const GlobalToast: React.FC<ToastProps> = ({toast}) => {
  const {message, isVisible, type} = toast || {};

  useEffect(() => {
    if (isVisible) {
      NotificationManager.removeAll();
      switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message, 'Success', 3000);
          break;
        case 'warning':
          NotificationManager.warning(message, 'Warning', 3000);
          break;
        case 'error':
          NotificationManager.error(message, 'Error', 5000);
          break;
        default:
          break;
      }
      setTimeout(closeGlobalToast, 3000); // Adjust the duration as needed
    }
  }, [isVisible, message, type, closeGlobalToast]);

  return <NotificationContainer />;
};

/**
 * Maps the Redux store state to component props.
 * @param {Object} state - The Redux store state.
 * @returns {ToastProps} The mapped component props.
 */
const mapStateToProps = (state: RootState): ToastProps => ({
  toast: state.toast,
});

/**
 * Maps the Redux dispatch to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} The mapped dispatch props.
 */
const mapDispatchToProps = {
  closeGlobalToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalToast);
