import {store} from '../../redux/store';
import {showModal, hideModal} from './reducer';
import {ModalMessage} from '../../interfaces/connected-components/globalModal';

export const showGlobalModal = ({
  message,
  title = '',
  type = 'info',
  onConfirm,
  onReject,
  customTopComponent,
  confirmText,
  rejectText,
}: ModalMessage) => {
  store.dispatch(
    showModal({
      message,
      title,
      type,
      onConfirm,
      onReject,
      customTopComponent,
      confirmText,
      rejectText,
    }),
  );
};

/**
 * This function close global modal
 */
export const closeGlobalModal = () => {
  store.dispatch(hideModal());
};
