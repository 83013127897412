import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../../theme/lightTheme';
import GenericButton from '../../../components/button';
import {changePassword} from './actions';
import GenericInput from '../../../components/textInput';
import {tr} from '../../../translation';
import {checkIfFormValid, handleChange} from '../../../utils/form';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4%;
  margin-bottom: 5%;
  margin-top: 5%;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  width: ${({theme}) => (theme?.isMobile ? '90%' : '55%')};
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  border-radius: 26px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10%;
  width: 95%;
  align-self: center;
  @media (min-width: 768px) {
    width: 75%;
  }
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s6};
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 5%;
  margin: 0;
`;
// Define the mandatory fields for the form
const mandatoryFields = ['oldPassword', 'newPassword', 'confirmPassword'];
/**
 * ChangePassword screen component.
 *
 * @param {any} props - The screen component props.
 */
const ChangePassword: React.FC<any> = () => {
  const theme = useTheme() as Theme;

  const [form, updateForm] = useState<any>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  // Define the form completion state
  const [formComplete, setFormComplete] = useState(false);

  useEffect(() => {
    setFormComplete(checkIfFormValid(form, mandatoryFields));
  }, [form]);

  const _renderItem = (item: any) => {
    return (
      <GenericInput
        key={item}
        placeholder={tr(`changePassword.${item}`)}
        rightIconColor={theme.text.light}
        value={form[item]}
        onChange={e => handleChange(item, e.target.value, updateForm)}
        secureInput
        type={'password'}
        id={'password'}
        containerStyle={{marginTop: '4%'}}
        hasError={
          item === 'confirmPassword' &&
          formComplete &&
          form.newPassword !== form.confirmPassword
        }
        enableErrorMessage
        errorMessage={tr('changePassword.error')}
      />
    );
  };

  return (
    <Container>
      <FormContainer>
        <Title>{tr('changePassword.title')}</Title>
        <InputContainer>{mandatoryFields.map(_renderItem)}</InputContainer>
        <GenericButton
          disabled={!formComplete || form.newPassword !== form.confirmPassword}
          onPress={() => changePassword(form)}
          radius={100}
          backgroundColor={'transparent'}
          isSmall
          label={tr('changePassword.save')}
          style={{
            minWidth: '22%',
          }}
        />
      </FormContainer>
    </Container>
  );
};

export default ChangePassword;
