import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    viewBox={'0 0 18 20'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeWidth={2}
      d="M4.062 4.586C5.636 4.132 7.352 3.098 9 1.456c1.647 1.642 3.364 2.676 4.938 3.13 1.029.296 2.066.356 2.97.115.01.31.021.615.033.917.084 2.204.162 4.23-.388 6.661-.552 2.437-2.08 4.116-3.702 5.203-.81.543-1.633.928-2.338 1.176C9.788 18.913 9.25 19 9 19c-.64 0-2.23-.253-3.812-1.235-1.545-.96-3.092-2.619-3.74-5.486-.55-2.432-.473-4.457-.389-6.66.012-.303.023-.608.033-.918.904.241 1.941.182 2.97-.115Z"
    />
  </svg>
);
export default SvgComponent;
