import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    viewBox={'0 0 16 18'}
    {...props}
    transform="scale(0.77)"
    fill="none"
    stroke="none">
    <path
      fill="url(#a)"
      fillOpacity={0.85}
      d="M7 17a1 1 0 1 0 2 0H7ZM8.707.293a1 1 0 0 0-1.414 0L.929 6.657A1 1 0 0 0 2.343 8.07L8 2.414l5.657 5.657a1 1 0 1 0 1.414-1.414L8.707.293ZM9 17V1H7v16h2Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={8}
        x2={9}
        y1={9.54}
        y2={9.54}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#009F9C" />
        <stop offset={1} stopColor="#007BB2" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
