import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={16}
    viewBox={'0 0 19 16'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      stroke={props.fill || '#fff'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 11C1 5.477 4.806 1 9.5 1S18 5.477 18 11"
    />
    <rect width={6} height={7} y={9} fill="#fff" rx={3} />
    <rect width={6} height={7} x={13} y={9} fill="#fff" rx={3} />
  </svg>
);
export default SvgComponent;
