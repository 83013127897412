import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageState} from '../interfaces/redux/language';

const initialState: LanguageState = {
  languageCode: 'en',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    saveLanguage: (state, action: PayloadAction<'en' | 'ar'>) => {
      state.languageCode = action.payload;
    },
  },
});

export const {saveLanguage} = languageSlice.actions;
export default languageSlice.reducer;
