import {EffectCallback, useLayoutEffect, useRef} from 'react';
import isEqual from 'lodash.isequal';

// Custom hook that triggers effect only if the deep comparison detects changes
export const useDeepCompareEffect = (effect: EffectCallback, deps: any[]) => {
  const previousDeps = useRef<any[]>([]);

  useLayoutEffect(() => {
    if (!isEqual(previousDeps.current, deps)) {
      effect();
    }

    previousDeps.current = deps;
  }, [deps, effect]);
};
