import React from 'react';
import styled, {useTheme} from 'styled-components';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {Theme} from '../../theme/lightTheme';
import {TextProps} from '../../interfaces/components/text';

/**
 * DefaultText styled component that represents the base text component.
 */
const DefaultText = styled.span<{type: string; languageCode: string}>`
  font-family: ${({theme, languageCode}) => theme.fonts[languageCode]};
  font-weight: ${({type}) =>
    type === 'bold' ? 700 : type === 'semiBold' ? 500 : 400};
`;

/**
 * GradientText styled component that represents the text with a gradient effect.
 */
const GradientText = styled.span`
  background: ${({theme}) =>
    `linear-gradient(45deg, ${theme.primary}, ${theme.secondary})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

/**
 * Text component that renders text with customizable style and font family.
 * @param {Object} props - Component props.
 * @returns {React.Component} The Text component.
 */
const Text: React.FC<TextProps> = props => {
  const {bold, semiBold, withGradient, children, style} = props;
  const theme = useTheme() as Theme;
  const {languageCode} = useSelector((state: RootState) => state.lang);

  const renderText = () => (
    <DefaultText
      languageCode={languageCode || 'en'}
      type={bold ? 'bold' : semiBold ? 'semiBold' : 'regular'}
      style={style}>
      {children}
    </DefaultText>
  );

  const renderGradientText = () => (
    <GradientText theme={theme}>{renderText()}</GradientText>
  );

  return withGradient ? renderGradientText() : renderText();
};

export default Text;
