import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthState, CurrentUserConfig} from '../../../interfaces/reducers/auth';
import {LoginDataType} from '../../../interfaces/api/auth/login';
import {initialFontReducer} from '../../../assets/static/fontChanger';

const initialState: AuthState = {
  currentUser: {},
  config: {
    font: initialFontReducer,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginDataType | null>) => {
      state.currentUser = action.payload;
    },
    setConfig: (state, action: PayloadAction<CurrentUserConfig>) => {
      state.config = action.payload;
    },
    logout: state => {
      state.currentUser = null;
    },
    setIsVoiceTabNewsPlayed: state => {
      state.isVoiceTabNewsPlayed = true;
    },
    setIsVoiceTabInteractionPlayed: state => {
      state.isVoiceTabInteractionPlayed = true;
    },
    resetTabsVoice: state => {
      state.isVoiceTabNewsPlayed = false;
      state.isVoiceTabInteractionPlayed = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setConfig,
  logout,
  setIsVoiceTabNewsPlayed,
  setIsVoiceTabInteractionPlayed,
  resetTabsVoice,
} = authSlice.actions;

export default authSlice.reducer;
